@import './halfmoon.min.css';

body {
    font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
}

.hide {
    display: none !important;
}

.dark-mode {
    background-color: #000000;

    .card {
        background-color: #000000;

        &-title {
            text-align: center;

            font-size: 32px;
            font-weight: bolder;

            background: -webkit-gradient(linear, left top, left bottom, from(#ffe3be), color-stop(50%, #ffe3be), color-stop(#dab585), color-stop(60%, #dab585), color-stop(#a17a58), to(#ffcc66));

            background: -o-linear-gradient(top, #ffe3be, #ffe3be 50%, #dab585, #dab585 60%, #a17a58, #ffcc66);

            background: linear-gradient(to bottom, #ffe3be, #ffe3be 50%, #dab585, #dab585 60%, #a17a58, #ffcc66);
            -webkit-background-clip: text;
            color: transparent;
        }
    }

    .input-group-text {
        background-color: #fff;
        color: #656571;

        img {
            height: auto;
            width: 1em;
        }
    }

    .input-group-prepend+.form-control {
        border-left: none;
    }

    .btn,
    .form-control {
        height: 4rem;
    }

    .form-group.is-invalid {
        .input-group-text {
            border-color: #ff4d4f;
        }

        .form-control,
        .form-control:hover,
        .form-control:focus {
            color: black;
            background-color: #fff;
            border-color: #ff4d4f;
            -webkit-box-shadow: none;
            box-shadow: none;

            &::placeholder {
                color: #656571;
                opacity: 1;
            }
        }
    }

    .form-control,
    .form-control:hover,
    .form-control:focus {
        color: black;
        background-color: #fff;
        border-color: rgba(0, 0, 0, .3);
        -webkit-box-shadow: none;
        box-shadow: none;

        &::placeholder {
            color: #656571;
            opacity: 1;
        }
    }

    hr {
        background-color: white;
    }

    .btn {
        &.btn-primary {
            background-color: #ab8256;
            border-color: #ab8256;
            font-weight: bold;

            &:hover,
            &:focus {
                background-color: #ab8256 !important;
                border-color: #ab8256 !important;
            }
        }

        &.btn-token {
            background-color: #e3d7c9;
            border-color: #e3d7c9;
            font-weight: bold;
            color: #1A1A1A;
        }
    }

    a {
        color: #ab8256;
        font-weight: bold;

        &:hover {
            color: #ab8256;
        }
    }
}

.image-logo {
    width: 70px;
    height: auto;
}