/*
* -----------------------------------------------------------------------------
* Halfmoon CSS
* Version: 1.0.4
* https://www.gethalfmoon.com
* Copyright, Halfmoon UI
* Licensed under MIT (https://www.gethalfmoon.com/license)
* -----------------------------------------------------------------------------
* The above notice must be included in its entirety when this file is used.
*/
html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 62.5%
}

body,
h1 {
    font-weight: 400;
    color: rgba(0, 0, 0, .85)
}

body {
    background-color: #fff;
    font-size: 1.4rem;
    line-height: 1.5;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
}

details,
main {
    display: block
}

h1 {
    font-size: 2em;
    margin: .67em 0;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 4rem;
    line-height: 1.3
}

hr {
    box-sizing: content-box;
    overflow: visible;
    height: 1px;
    color: rgba(0, 0, 0, .05);
    background-color: rgba(0, 0, 0, .05);
    border: 0
}

code,
kbd,
pre,
samp {
    font-size: 1em
}

pre,
samp {
    font-family: monospace, monospace
}

a {
    background-color: transparent;
    color: #1890ff;
    text-decoration: none
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline dotted
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

img {
    border-style: none
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
    outline: 1px dotted ButtonText
}

fieldset {
    padding: 0;
    border-width: 0
}

legend {
    color: inherit;
    display: table;
    max-width: 100%;
    white-space: normal;
    display: block;
    margin-bottom: .6rem
}

progress {
    vertical-align: baseline
}

textarea {
    overflow: auto
}

[type=checkbox],
[type=radio],
legend {
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

[hidden],
template {
    display: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td,
th {
    padding: 0
}

*,
:after,
:before {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit
}

@media (min-width:1600px) {
    html {
        font-size: 75%
    }
}

@media (min-width:1920px) {
    html {
        font-size: 87.5%
    }
}

.dark-mode {
    background-color: #25282c;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.dark-mode hr {
    color: rgba(255, 255, 255, .05);
    background-color: rgba(255, 255, 255, .05)
}

h2,
h3,
h4,
h5,
h6 {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-weight: 400;
    color: rgba(0, 0, 0, .85)
}

.dark-mode,
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6 {
    color: rgba(255, 255, 255, .8)
}

h2 {
    font-size: 3.6rem;
    line-height: 1.35
}

h3 {
    font-size: 3rem;
    line-height: 1.4
}

h4 {
    font-size: 2.4rem;
    line-height: 1.45
}

h5 {
    font-size: 1.8rem
}

h6 {
    font-size: 1.5rem
}

@media (min-width:769px) {
    .with-custom-webkit-scrollbars ::-webkit-scrollbar {
        width: 1.4rem;
        height: 1.4rem;
        background-color: transparent
    }

    .dark-mode.with-custom-webkit-scrollbars ::-webkit-scrollbar {
        background-color: transparent
    }

    .with-custom-webkit-scrollbars ::-webkit-scrollbar-track:vertical {
        border-left: 1px solid rgba(0, 0, 0, .1)
    }

    .with-custom-webkit-scrollbars ::-webkit-scrollbar-track:horizontal {
        border-top: 1px solid rgba(0, 0, 0, .1)
    }

    .dark-mode.with-custom-webkit-scrollbars ::-webkit-scrollbar-track {
        border-color: rgba(255, 255, 255, .05)
    }

    .with-custom-webkit-scrollbars ::-webkit-scrollbar-thumb {
        min-width: 3rem;
        min-height: 3rem;
        background-color: rgba(0, 0, 0, .25);
        border: .4rem solid transparent;
        -moz-background-clip: content;
        -webkit-background-clip: content;
        background-clip: content-box;
        border-radius: 1rem
    }

    .with-custom-webkit-scrollbars ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, .4);
        border-color: transparent
    }

    .dark-mode.with-custom-webkit-scrollbars ::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, .25);
        border-color: transparent
    }

    .dark-mode.with-custom-webkit-scrollbars .sidebar::-webkit-scrollbar-thumb:hover,
    .dark-mode.with-custom-webkit-scrollbars ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(255, 255, 255, .4);
        border-color: transparent
    }

    .with-custom-webkit-scrollbars ::-webkit-scrollbar-corner {
        background-color: transparent;
        border-left: 1px solid rgba(0, 0, 0, .1);
        border-top: 1px solid rgba(0, 0, 0, .1)
    }

    .dark-mode.with-custom-webkit-scrollbars ::-webkit-scrollbar-corner {
        background-color: transparent;
        border-color: rgba(255, 255, 255, .05)
    }

    .with-custom-css-scrollbars * {
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, .25) #fff
    }

    .dark-mode.with-custom-css-scrollbars * {
        scrollbar-color: rgba(255, 255, 255, .25) #25282c
    }

    .with-custom-webkit-scrollbars .sidebar::-webkit-scrollbar {
        width: 1.4rem;
        height: 1.4rem;
        background-color: transparent
    }

    .dark-mode.with-custom-webkit-scrollbars .sidebar::-webkit-scrollbar {
        background-color: transparent
    }

    .with-custom-webkit-scrollbars .sidebar::-webkit-scrollbar-track {
        border-width: 1px;
        border-color: rgba(0, 0, 0, .1)
    }

    .dark-mode.with-custom-webkit-scrollbars .sidebar::-webkit-scrollbar-track {
        border-color: rgba(255, 255, 255, .05)
    }

    .with-custom-webkit-scrollbars .sidebar::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, .25);
        border-width: .4rem;
        border-color: transparent;
        border-radius: 1rem
    }

    .with-custom-webkit-scrollbars .sidebar::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, .4);
        border-color: transparent
    }

    .dark-mode.with-custom-webkit-scrollbars .sidebar::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, .25);
        border-color: transparent
    }

    .with-custom-webkit-scrollbars .sidebar::-webkit-scrollbar-corner {
        background-color: transparent;
        border-width: 1px;
        border-color: rgba(0, 0, 0, .1)
    }

    .dark-mode.with-custom-webkit-scrollbars .sidebar::-webkit-scrollbar-corner {
        background-color: transparent;
        border-color: rgba(255, 255, 255, .05)
    }

    .with-custom-css-scrollbars .sidebar {
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, .25) #fff
    }

    .dark-mode.with-custom-css-scrollbars .modal-full .modal-dialog,
    .dark-mode.with-custom-css-scrollbars .sidebar {
        scrollbar-color: rgba(255, 255, 255, .25) #191c20
    }

    .with-custom-webkit-scrollbars .modal-dialog::-webkit-scrollbar {
        width: 1.4rem;
        height: 1.4rem;
        background-color: transparent
    }

    .dark-mode.with-custom-webkit-scrollbars .modal-dialog::-webkit-scrollbar {
        background-color: transparent
    }

    .with-custom-webkit-scrollbars .modal-dialog::-webkit-scrollbar-track {
        border-width: 1px;
        border-color: rgba(255, 255, 255, .05)
    }

    .dark-mode.with-custom-webkit-scrollbars .modal-dialog::-webkit-scrollbar-track {
        border-color: rgba(255, 255, 255, .05)
    }

    .with-custom-webkit-scrollbars .modal-dialog::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, .25);
        border-width: .4rem;
        border-color: transparent;
        border-radius: 1rem
    }

    .dark-mode.with-custom-webkit-scrollbars .modal-dialog::-webkit-scrollbar-thumb:hover,
    .dark-mode.with-custom-webkit-scrollbars .modal-full .modal-dialog::-webkit-scrollbar-thumb:hover,
    .with-custom-webkit-scrollbars .modal-dialog::-webkit-scrollbar-thumb:hover {
        background-color: rgba(255, 255, 255, .4);
        border-color: transparent
    }

    .dark-mode.with-custom-webkit-scrollbars .modal-dialog::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, .25);
        border-color: transparent
    }

    .with-custom-webkit-scrollbars .modal-dialog::-webkit-scrollbar-corner {
        background-color: transparent;
        border-width: 1px;
        border-color: rgba(0, 0, 0, .1)
    }

    .dark-mode.with-custom-webkit-scrollbars .modal-dialog::-webkit-scrollbar-corner {
        background-color: transparent;
        border-color: rgba(255, 255, 255, .05)
    }

    .with-custom-css-scrollbars .modal-dialog {
        scrollbar-width: thin
    }

    .dark-mode.with-custom-css-scrollbars .modal-dialog,
    .with-custom-css-scrollbars .modal-dialog {
        scrollbar-color: rgba(255, 255, 255, .25) transparent
    }

    .with-custom-webkit-scrollbars .modal-full .modal-dialog::-webkit-scrollbar {
        width: 1.4rem;
        height: 1.4rem;
        background-color: #fff
    }

    .dark-mode.with-custom-webkit-scrollbars .modal-full .modal-dialog::-webkit-scrollbar {
        background-color: #191c20
    }

    .with-custom-webkit-scrollbars .modal-full .modal-dialog::-webkit-scrollbar-track {
        border-width: 1px;
        border-color: rgba(0, 0, 0, .1)
    }

    .dark-mode.with-custom-webkit-scrollbars .modal-full .modal-dialog::-webkit-scrollbar-track {
        border-color: rgba(255, 255, 255, .05)
    }

    .with-custom-webkit-scrollbars .modal-full .modal-dialog::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, .25);
        border-width: .4rem;
        border-color: transparent;
        border-radius: 1rem
    }

    .with-custom-webkit-scrollbars .modal-full .modal-dialog::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, .4);
        border-color: transparent
    }

    .dark-mode.with-custom-webkit-scrollbars .modal-full .modal-dialog::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, .25);
        border-color: transparent
    }

    .with-custom-webkit-scrollbars .modal-full .modal-dialog::-webkit-scrollbar-corner {
        background-color: #fff;
        border-width: 1px;
        border-color: rgba(0, 0, 0, .1)
    }

    .dark-mode.with-custom-webkit-scrollbars .modal-full .modal-dialog::-webkit-scrollbar-corner {
        background-color: #191c20;
        border-color: rgba(255, 255, 255, .05)
    }

    .with-custom-css-scrollbars .card,
    .with-custom-css-scrollbars .modal-full .modal-dialog {
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, .25) #fff
    }

    .with-custom-webkit-scrollbars .card::-webkit-scrollbar {
        width: 1.4rem;
        height: 1.4rem;
        background-color: transparent
    }

    .dark-mode.with-custom-webkit-scrollbars .card::-webkit-scrollbar {
        background-color: transparent
    }

    .with-custom-webkit-scrollbars .card::-webkit-scrollbar-track {
        border-width: 1px;
        border-color: rgba(0, 0, 0, .1)
    }

    .dark-mode.with-custom-webkit-scrollbars .card::-webkit-scrollbar-track {
        border-color: rgba(255, 255, 255, .05)
    }

    .with-custom-webkit-scrollbars .card::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, .25);
        border-width: .4rem;
        border-color: transparent;
        border-radius: 1rem
    }

    .with-custom-webkit-scrollbars .card::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, .4);
        border-color: transparent
    }

    .dark-mode.with-custom-webkit-scrollbars .card::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, .25);
        border-color: transparent
    }

    .dark-mode.with-custom-webkit-scrollbars .card::-webkit-scrollbar-thumb:hover {
        background-color: rgba(255, 255, 255, .4);
        border-color: transparent
    }

    .with-custom-webkit-scrollbars .card::-webkit-scrollbar-corner {
        background-color: transparent;
        border-width: 1px;
        border-color: rgba(0, 0, 0, .1)
    }

    .dark-mode.with-custom-webkit-scrollbars .card::-webkit-scrollbar-corner {
        background-color: transparent;
        border-color: rgba(255, 255, 255, .05)
    }

    .dark-mode.with-custom-css-scrollbars .card {
        scrollbar-color: rgba(255, 255, 255, .25) #191c20
    }
}

.page-wrapper,
body,
html {
    position: absolute;
    height: 100%;
    width: 100%
}

body,
html {
    margin: 0;
    padding: 0;
    left: 0;
    top: 0
}

.page-wrapper {
    overflow: hidden
}

.sticky-alerts {
    position: fixed;
    top: 0;
    right: 2.4rem;
    z-index: 100
}

.dark-mode .navbar,
.navbar {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, .2)
}

.dark-mode .navbar {
    background-color: #111417;
    border-color: rgba(0, 0, 0, .2)
}

.navbar,
.navbar.navbar-fixed-bottom {
    height: 5rem
}

.dark-mode .navbar.navbar-fixed-bottom,
.dark-mode .navbar.navbar-static-bottom {
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.navbar.navbar-fixed-bottom,
.navbar.navbar-static-bottom {
    border-bottom: none;
    border-top: 1px solid rgba(0, 0, 0, .2);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.page-wrapper>.navbar {
    position: fixed;
    left: 0;
    z-index: 40;
    display: none
}

.page-wrapper>.navbar:not(.navbar-fixed-bottom) {
    top: 0
}

.page-wrapper.with-navbar>.navbar:not(.navbar-fixed-bottom) {
    display: -ms-flexbox;
    display: flex
}

.page-wrapper>.navbar.navbar-fixed-bottom {
    bottom: 0
}

.page-wrapper.with-navbar-fixed-bottom>.navbar.navbar-fixed-bottom {
    display: -ms-flexbox;
    display: flex
}

.dark-mode .sidebar,
.sidebar {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 26rem;
    max-width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 20;
    background-color: #fff;
    border-right: 1px solid rgba(0, 0, 0, .2)
}

.dark-mode .sidebar {
    background-color: #191c20;
    border-color: rgba(0, 0, 0, .2)
}

.page-wrapper>.sidebar {
    display: none
}

.modal-content-media img,
.modal-content-media video,
.page-wrapper.with-sidebar>.sidebar {
    display: block
}

.content-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    overflow: auto
}

.page-wrapper.with-navbar>.sticky-alerts {
    top: 5rem
}

.page-wrapper.with-navbar>.content-wrapper,
.page-wrapper.with-navbar>.sidebar {
    top: 5rem;
    height: calc(100% - 5rem)
}

.page-wrapper.with-sidebar>.content-wrapper {
    left: 26rem;
    width: calc(100% - 26rem)
}

@media (max-width:768px) {
    .page-wrapper.with-sidebar>.content-wrapper {
        width: 100%
    }
}

.page-wrapper.with-sidebar[data-sidebar-hidden]>.sidebar {
    left: -100%
}

.page-wrapper.with-sidebar[data-sidebar-hidden]>.content-wrapper {
    width: 100%;
    left: 0
}

.page-wrapper.with-navbar-fixed-bottom>.content-wrapper,
.page-wrapper.with-navbar-fixed-bottom>.sidebar {
    bottom: 5rem;
    height: calc(100% - 5rem)
}

.page-wrapper.with-navbar.with-navbar-fixed-bottom>.content-wrapper,
.page-wrapper.with-navbar.with-navbar-fixed-bottom>.sidebar {
    top: 5rem;
    bottom: 5rem;
    height: calc(100% - 5rem - 5rem)
}

.page-wrapper.with-sidebar[data-sidebar-type~=full-height]>.sidebar {
    height: 100%;
    top: 0
}

.page-wrapper.with-sidebar[data-sidebar-type~=full-height]>.navbar:not(.navbar-fixed-bottom) {
    left: 26rem;
    width: calc(100% - 26rem)
}

.page-wrapper.with-sidebar[data-sidebar-type~=full-height]>.navbar.navbar-fixed-bottom {
    left: 26rem;
    width: calc(100% - 26rem)
}

@media (max-width:768px) {

    .page-wrapper.with-sidebar[data-sidebar-type~=full-height]>.navbar.navbar-fixed-bottom,
    .page-wrapper.with-sidebar[data-sidebar-type~=full-height]>.navbar:not(.navbar-fixed-bottom) {
        width: 100%
    }
}

.page-wrapper.with-sidebar[data-sidebar-type~=full-height][data-sidebar-hidden]>.navbar.navbar-fixed-bottom,
.page-wrapper.with-sidebar[data-sidebar-type~=full-height][data-sidebar-hidden]>.navbar:not(.navbar-fixed-bottom),
.page-wrapper.with-sidebar[data-sidebar-type~=overlayed-all]>.content-wrapper,
.page-wrapper.with-sidebar[data-sidebar-type~=overlayed-all]>.navbar.navbar-fixed-bottom,
.page-wrapper.with-sidebar[data-sidebar-type~=overlayed-all]>.navbar:not(.navbar-fixed-bottom) {
    left: 0;
    width: 100%
}

.page-wrapper.with-sidebar[data-sidebar-type~=overlayed-all]>.sidebar {
    height: 100%;
    top: 0;
    z-index: 50
}

.page-wrapper.with-sidebar[data-sidebar-type~=overlayed-all]>.sidebar-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 45;
    background-color: rgba(0, 0, 0, .75)
}

.page-wrapper.with-sidebar[data-sidebar-type~=overlayed-all][data-sidebar-hidden]>.sidebar-overlay {
    display: none
}

@media (max-width:768px) {

    .page-wrapper.with-sidebar[data-sidebar-type~=overlayed-sm-and-down]>.content-wrapper,
    .page-wrapper.with-sidebar[data-sidebar-type~=overlayed-sm-and-down]>.navbar.navbar-fixed-bottom,
    .page-wrapper.with-sidebar[data-sidebar-type~=overlayed-sm-and-down]>.navbar:not(.navbar-fixed-bottom) {
        left: 0;
        width: 100%
    }

    .page-wrapper.with-sidebar[data-sidebar-type~=overlayed-sm-and-down]>.sidebar {
        height: 100%;
        top: 0;
        z-index: 50
    }

    .page-wrapper.with-sidebar[data-sidebar-type~=overlayed-sm-and-down]>.sidebar-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 45;
        background-color: rgba(0, 0, 0, .75)
    }

    .page-wrapper.with-sidebar[data-sidebar-type~=overlayed-sm-and-down][data-sidebar-hidden]>.sidebar-overlay {
        display: none
    }
}

.page-wrapper.with-transitions.with-sidebar .content-wrapper,
.page-wrapper.with-transitions.with-sidebar>.sidebar,
.page-wrapper.with-transitions.with-sidebar[data-sidebar-type~=full-height]>.navbar.navbar-fixed-bottom,
.page-wrapper.with-transitions.with-sidebar[data-sidebar-type~=full-height]>.navbar:not(.navbar-fixed-bottom),
.page-wrapper.with-transitions.with-sidebar[data-sidebar-type~=overlayed-all]>.sidebar {
    -webkit-transition: all .4s cubic-bezier(.25, .8, .25, 1);
    transition: all .4s cubic-bezier(.25, .8, .25, 1)
}

@media (max-width:768px) {
    .page-wrapper.with-transitions.with-sidebar[data-sidebar-type~=overlayed-sm-and-down]>.sidebar {
        -webkit-transition: all .4s cubic-bezier(.25, .8, .25, 1);
        transition: all .4s cubic-bezier(.25, .8, .25, 1)
    }
}

.content-wrapper,
.sidebar {
    -webkit-overflow-scrolling: touch
}

.container,
.container-fluid {
    margin-left: auto;
    margin-right: auto
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    width: 100%
}

@media (min-width:577px) {

    .container,
    .container-sm {
        max-width: 54rem
    }
}

@media (min-width:769px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 72rem
    }
}

@media (min-width:993px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 96rem
    }
}

@media (min-width:1201px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 114rem
    }
}

.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9 {
    position: relative;
    width: 100%
}

.col-auto {
    position: relative
}

.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%
}

.col {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1,
.col-2,
.col-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
}

.col-2,
.col-3 {
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
}

.col-3 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.col-4,
.col-5,
.col-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
}

.col-5,
.col-6 {
    -webkit-flex: 0 0 41.6666666667%;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
}

.col-6 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-7,
.col-8,
.col-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.3333333333%;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
}

.col-8,
.col-9 {
    -webkit-flex: 0 0 66.6666666667%;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
}

.col-9 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
}

.col-10,
.col-11,
.col-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.3333333333%;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
}

.col-11,
.col-12 {
    -webkit-flex: 0 0 91.6666666667%;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
}

.col-12 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1
}

.order-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13
}

.order-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0
}

.order-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1
}

.order-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2
}

.order-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3
}

.order-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4
}

.order-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5
}

.order-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6
}

.order-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7
}

.order-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8
}

.order-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9
}

.order-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10
}

.order-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11
}

.order-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12
}

.offset-1 {
    margin-left: 8.3333333333%
}

.offset-2 {
    margin-left: 16.6666666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.3333333333%
}

.offset-5 {
    margin-left: 41.6666666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.3333333333%
}

.offset-8 {
    margin-left: 66.6666666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.3333333333%
}

.offset-11 {
    margin-left: 91.6666666667%
}

@media (min-width:577px) {
    .col-sm {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-sm-1,
    .col-sm-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.3333333333%;
        -ms-flex: 0 0 8.3333333333%;
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-sm-2 {
        -webkit-flex: 0 0 16.6666666667%;
        -ms-flex: 0 0 16.6666666667%;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-sm-3,
    .col-sm-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        -webkit-flex: 0 0 33.3333333333%;
        -ms-flex: 0 0 33.3333333333%;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-sm-5,
    .col-sm-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.6666666667%;
        -ms-flex: 0 0 41.6666666667%;
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-sm-6 {
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7,
    .col-sm-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.3333333333%;
        -ms-flex: 0 0 58.3333333333%;
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-sm-8 {
        -webkit-flex: 0 0 66.6666666667%;
        -ms-flex: 0 0 66.6666666667%;
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-sm-10,
    .col-sm-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        -webkit-flex: 0 0 83.3333333333%;
        -ms-flex: 0 0 83.3333333333%;
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-sm-11,
    .col-sm-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.6666666667%;
        -ms-flex: 0 0 91.6666666667%;
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-sm-12 {
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .order-sm-last {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -ms-flex-order: 13;
        order: 13
    }

    .order-sm-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0
    }

    .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
    }

    .order-sm-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3
    }

    .order-sm-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4
    }

    .order-sm-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5
    }

    .order-sm-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6
    }

    .order-sm-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7
    }

    .order-sm-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8
    }

    .order-sm-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9
    }

    .order-sm-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10
    }

    .order-sm-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11
    }

    .order-sm-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.3333333333%
    }

    .offset-sm-2 {
        margin-left: 16.6666666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.3333333333%
    }

    .offset-sm-5 {
        margin-left: 41.6666666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.3333333333%
    }

    .offset-sm-8 {
        margin-left: 66.6666666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.3333333333%
    }

    .offset-sm-11 {
        margin-left: 91.6666666667%
    }
}

@media (min-width:769px) {
    .col-md {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-md-1,
    .col-md-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.3333333333%;
        -ms-flex: 0 0 8.3333333333%;
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-md-2 {
        -webkit-flex: 0 0 16.6666666667%;
        -ms-flex: 0 0 16.6666666667%;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-md-3,
    .col-md-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        -webkit-flex: 0 0 33.3333333333%;
        -ms-flex: 0 0 33.3333333333%;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-md-5,
    .col-md-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.6666666667%;
        -ms-flex: 0 0 41.6666666667%;
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-md-6 {
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7,
    .col-md-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.3333333333%;
        -ms-flex: 0 0 58.3333333333%;
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-md-8 {
        -webkit-flex: 0 0 66.6666666667%;
        -ms-flex: 0 0 66.6666666667%;
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-md-10,
    .col-md-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        -webkit-flex: 0 0 83.3333333333%;
        -ms-flex: 0 0 83.3333333333%;
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-md-11,
    .col-md-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.6666666667%;
        -ms-flex: 0 0 91.6666666667%;
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-md-12 {
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .order-md-last {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -ms-flex-order: 13;
        order: 13
    }

    .order-md-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0
    }

    .order-md-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }

    .order-md-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
    }

    .order-md-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3
    }

    .order-md-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4
    }

    .order-md-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5
    }

    .order-md-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6
    }

    .order-md-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7
    }

    .order-md-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8
    }

    .order-md-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9
    }

    .order-md-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10
    }

    .order-md-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11
    }

    .order-md-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.3333333333%
    }

    .offset-md-2 {
        margin-left: 16.6666666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.3333333333%
    }

    .offset-md-5 {
        margin-left: 41.6666666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.3333333333%
    }

    .offset-md-8 {
        margin-left: 66.6666666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.3333333333%
    }

    .offset-md-11 {
        margin-left: 91.6666666667%
    }
}

@media (min-width:993px) {
    .col-lg {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-lg-1,
    .col-lg-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.3333333333%;
        -ms-flex: 0 0 8.3333333333%;
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-lg-2 {
        -webkit-flex: 0 0 16.6666666667%;
        -ms-flex: 0 0 16.6666666667%;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-lg-3,
    .col-lg-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        -webkit-flex: 0 0 33.3333333333%;
        -ms-flex: 0 0 33.3333333333%;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-lg-5,
    .col-lg-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.6666666667%;
        -ms-flex: 0 0 41.6666666667%;
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-lg-6 {
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7,
    .col-lg-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.3333333333%;
        -ms-flex: 0 0 58.3333333333%;
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-lg-8 {
        -webkit-flex: 0 0 66.6666666667%;
        -ms-flex: 0 0 66.6666666667%;
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-lg-10,
    .col-lg-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        -webkit-flex: 0 0 83.3333333333%;
        -ms-flex: 0 0 83.3333333333%;
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-lg-11,
    .col-lg-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.6666666667%;
        -ms-flex: 0 0 91.6666666667%;
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-lg-12 {
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .order-lg-last {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -ms-flex-order: 13;
        order: 13
    }

    .order-lg-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0
    }

    .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }

    .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
    }

    .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3
    }

    .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4
    }

    .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5
    }

    .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6
    }

    .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7
    }

    .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8
    }

    .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9
    }

    .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10
    }

    .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11
    }

    .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.3333333333%
    }

    .offset-lg-2 {
        margin-left: 16.6666666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.3333333333%
    }

    .offset-lg-5 {
        margin-left: 41.6666666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.3333333333%
    }

    .offset-lg-8 {
        margin-left: 66.6666666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.3333333333%
    }

    .offset-lg-11 {
        margin-left: 91.6666666667%
    }
}

@media (min-width:1201px) {
    .col-xl {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xl-1,
    .col-xl-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.3333333333%;
        -ms-flex: 0 0 8.3333333333%;
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-xl-2 {
        -webkit-flex: 0 0 16.6666666667%;
        -ms-flex: 0 0 16.6666666667%;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-xl-3,
    .col-xl-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        -webkit-flex: 0 0 33.3333333333%;
        -ms-flex: 0 0 33.3333333333%;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-xl-5,
    .col-xl-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.6666666667%;
        -ms-flex: 0 0 41.6666666667%;
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-xl-6 {
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7,
    .col-xl-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.3333333333%;
        -ms-flex: 0 0 58.3333333333%;
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-xl-8 {
        -webkit-flex: 0 0 66.6666666667%;
        -ms-flex: 0 0 66.6666666667%;
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-xl-10,
    .col-xl-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        -webkit-flex: 0 0 83.3333333333%;
        -ms-flex: 0 0 83.3333333333%;
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-xl-11,
    .col-xl-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.6666666667%;
        -ms-flex: 0 0 91.6666666667%;
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-xl-12 {
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .order-xl-last {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -ms-flex-order: 13;
        order: 13
    }

    .order-xl-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0
    }

    .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }

    .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
    }

    .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3
    }

    .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4
    }

    .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5
    }

    .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6
    }

    .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7
    }

    .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8
    }

    .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9
    }

    .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10
    }

    .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11
    }

    .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.3333333333%
    }

    .offset-xl-2 {
        margin-left: 16.6666666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.3333333333%
    }

    .offset-xl-5 {
        margin-left: 41.6666666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.3333333333%
    }

    .offset-xl-8 {
        margin-left: 66.6666666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.3333333333%
    }

    .offset-xl-11 {
        margin-left: 91.6666666667%
    }
}

.row.row-eq-spacing {
    margin-top: 3rem;
    margin-bottom: 3rem
}

.row.row-eq-spacing,
.row.row-eq-spacing>[class^=col] {
    padding-left: calc(3rem/2);
    padding-right: calc(3rem/2)
}

.row.row-eq-spacing>[class^=col]>.card,
.row.row-eq-spacing>[class^=col]>.content {
    margin-left: 0;
    margin-right: 0
}

.row.row-eq-spacing>[class^=col]>.card:first-child,
.row.row-eq-spacing>[class^=col]>.content:first-child {
    margin-top: 0
}

.row.row-eq-spacing>[class^=col]>.card:last-child,
.row.row-eq-spacing>[class^=col]>.content:last-child {
    margin-bottom: 0
}

@media (max-width:576px) {
    .row.row-eq-spacing {
        margin-top: 2rem;
        margin-bottom: 2rem
    }

    .row.row-eq-spacing,
    .row.row-eq-spacing>[class^=col] {
        padding-left: calc(2rem/2);
        padding-right: calc(2rem/2)
    }
}

@media (min-width:577px) {
    .row.row-eq-spacing-sm:not(.row-eq-spacing) {
        margin-top: 3rem;
        margin-bottom: 3rem
    }

    .row.row-eq-spacing-sm:not(.row-eq-spacing),
    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col] {
        padding-left: calc(3rem/2);
        padding-right: calc(3rem/2)
    }

    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.card,
    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.content {
        margin-left: 0;
        margin-right: 0
    }

    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.card:first-child,
    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.content:first-child {
        margin-top: 0
    }

    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.card:last-child,
    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.content:last-child {
        margin-bottom: 0
    }
}

@media (max-width:576px) {

    .row.row-eq-spacing-sm:not(.row-eq-spacing),
    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.card:not(:only-child),
    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.content:not(:only-child) {
        margin-top: 2rem;
        margin-bottom: 2rem
    }

    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col] {
        margin-top: calc(2rem/2);
        margin-bottom: calc(2rem/2)
    }

    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]:first-child,
    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.card:not(:only-child):first-child,
    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.content:not(:only-child):first-child {
        margin-top: 0
    }

    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]:last-child,
    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.card:not(:only-child):last-child,
    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.content:not(:only-child):last-child {
        margin-bottom: 0
    }

    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.card,
    .row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]>.content {
        margin-top: 0;
        margin-bottom: 0
    }
}

@media (min-width:769px) {
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing) {
        margin-top: 3rem;
        margin-bottom: 3rem;
        padding-left: calc(3rem/2);
        padding-right: calc(3rem/2)
    }

    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col] {
        padding-left: calc(3rem/2);
        padding-right: calc(3rem/2)
    }

    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card,
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content {
        margin-left: 0;
        margin-right: 0
    }

    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:first-child,
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:first-child {
        margin-top: 0
    }

    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:last-child,
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:last-child {
        margin-bottom: 0
    }
}

@media (max-width:768px) {

    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing),
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:not(:only-child),
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:not(:only-child) {
        margin-top: 3rem;
        margin-bottom: 3rem
    }

    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col] {
        margin-top: calc(3rem/2);
        margin-bottom: calc(3rem/2)
    }

    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:first-child,
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:not(:only-child):first-child,
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:not(:only-child):first-child {
        margin-top: 0
    }

    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:last-child,
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:not(:only-child):last-child,
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:not(:only-child):last-child {
        margin-bottom: 0
    }

    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card,
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content {
        margin-top: 0;
        margin-bottom: 0
    }
}

@media (max-width:576px) {

    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing),
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:not(:only-child),
    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:not(:only-child) {
        margin-top: 2rem;
        margin-bottom: 2rem
    }

    .row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col] {
        margin-top: calc(2rem/2);
        margin-bottom: calc(2rem/2)
    }
}

@media (min-width:993px) {
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing) {
        margin-top: 3rem;
        margin-bottom: 3rem;
        padding-left: calc(3rem/2);
        padding-right: calc(3rem/2)
    }

    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col] {
        padding-left: calc(3rem/2);
        padding-right: calc(3rem/2)
    }

    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card,
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content {
        margin-left: 0;
        margin-right: 0
    }

    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:first-child,
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:first-child {
        margin-top: 0
    }

    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:last-child,
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:last-child {
        margin-bottom: 0
    }
}

@media (max-width:992px) {

    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing),
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:not(:only-child),
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:not(:only-child) {
        margin-top: 3rem;
        margin-bottom: 3rem
    }

    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col] {
        margin-top: calc(3rem/2);
        margin-bottom: calc(3rem/2)
    }

    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:first-child,
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:not(:only-child):first-child,
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:not(:only-child):first-child {
        margin-top: 0
    }

    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:last-child,
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:not(:only-child):last-child,
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:not(:only-child):last-child {
        margin-bottom: 0
    }

    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card,
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content {
        margin-top: 0;
        margin-bottom: 0
    }
}

@media (max-width:576px) {

    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing),
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:not(:only-child),
    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:not(:only-child) {
        margin-top: 2rem;
        margin-bottom: 2rem
    }

    .row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col] {
        margin-top: calc(2rem/2);
        margin-bottom: calc(2rem/2)
    }
}

@media (min-width:1201px) {
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing) {
        margin-top: 3rem;
        margin-bottom: 3rem;
        padding-left: calc(3rem/2);
        padding-right: calc(3rem/2)
    }

    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col] {
        padding-left: calc(3rem/2);
        padding-right: calc(3rem/2)
    }

    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card,
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content {
        margin-left: 0;
        margin-right: 0
    }

    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:first-child,
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:first-child {
        margin-top: 0
    }

    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:last-child,
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:last-child {
        margin-bottom: 0
    }
}

@media (max-width:1200px) {

    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing),
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:not(:only-child),
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:not(:only-child) {
        margin-top: 3rem;
        margin-bottom: 3rem
    }

    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col] {
        margin-top: calc(3rem/2);
        margin-bottom: calc(3rem/2)
    }

    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:first-child,
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:not(:only-child):first-child,
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:not(:only-child):first-child {
        margin-top: 0
    }

    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:last-child,
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:not(:only-child):last-child,
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:not(:only-child):last-child {
        margin-bottom: 0
    }

    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card,
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content {
        margin-top: 0;
        margin-bottom: 0
    }
}

@media (max-width:576px) {

    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing),
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.card:not(:only-child),
    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]>.content:not(:only-child) {
        margin-top: 2rem;
        margin-bottom: 2rem
    }

    .row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col] {
        margin-top: calc(2rem/2);
        margin-bottom: calc(2rem/2)
    }
}

.content {
    margin: 3rem
}

@media (max-width:576px) {
    .content {
        margin: 2rem
    }
}

.content-title {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 0
}

.card,
.dark-mode .card {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.card {
    position: relative;
    margin: 3rem;
    padding: 3rem;
    color: rgba(0, 0, 0, .85);
    background-color: #fff;
    border-radius: .4rem;
    border: 1px solid rgba(0, 0, 0, .2)
}

.dark-mode .card {
    color: rgba(255, 255, 255, .8);
    background-color: #191c20;
    border-color: rgba(0, 0, 0, .2)
}

@media (max-width:576px) {
    .card {
        margin: 2rem;
        padding: 2rem
    }
}

.card-title {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 0
}

.v-spacer {
    height: 3rem;
    position: relative;
    width: 100%
}

@media (max-width:576px) {
    .v-spacer {
        height: 2rem
    }
}

a:hover {
    color: #45a3fb;
    text-decoration: underline
}

.dark-mode a {
    color: #1890ff
}

.dark-mode a:hover {
    color: #45a3fb
}

.btn,
.btn:hover {
    color: rgba(0, 0, 0, .7);
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .05)
}

.btn {
    display: inline-block;
    height: 3.2rem;
    line-height: 3.2rem;
    padding: 0 1.5rem;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .4rem
}

.btn:hover {
    text-decoration: none;
    background-color: #f7f7f7;
    border-color: rgba(0, 0, 0, .2)
}

.btn:focus {
    color: rgba(0, 0, 0, .7);
    background-color: #fff;
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    outline: 1px dotted transparent;
    outline-offset: 2px
}

.btn.active {
    color: rgba(0, 0, 0, .7);
    background-color: #fff;
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    outline: 1px dotted transparent;
    outline-offset: 2px
}

.btn:focus:hover,
.custom-file input[type=file]:focus:hover~label,
.form-group.is-invalid .custom-file input[type=file]:focus:hover~label {
    color: rgba(0, 0, 0, .7);
    background-color: #f7f7f7
}

.btn.active:hover {
    color: rgba(0, 0, 0, .7);
    background-color: #f7f7f7
}

.dark-mode .btn,
.dark-mode .btn:hover {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: transparent;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .1)
}

.dark-mode .btn:hover {
    background-color: rgba(255, 255, 255, .1)
}

.dark-mode .btn:focus {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    outline-color: transparent
}

.dark-mode .btn.active {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    outline-color: transparent
}

.dark-mode .btn:focus:hover,
.dark-mode .custom-file input[type=file]:focus:hover~label,
.dark-mode .form-group.is-invalid .custom-file input[type=file]:focus:hover~label {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .1)
}

.dark-mode .btn.active:hover {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .1)
}

.btn.disabled,
.btn:disabled {
    cursor: not-allowed;
    opacity: .6
}

.btn.disabled,
.btn.disabled:hover {
    color: rgba(0, 0, 0, .7);
    background-color: #fff;
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn:disabled {
    color: rgba(0, 0, 0, .7);
    background-color: #fff;
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.disabled:focus {
    color: rgba(0, 0, 0, .7);
    background-color: #fff;
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.disabled,
.dark-mode .btn.disabled:hover {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: transparent;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn:disabled {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: transparent;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.disabled:focus {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: transparent;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.alt-dm,
.dark-mode .btn.alt-dm:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, .15);
    border-color: rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .025);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .025);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .025)
}

.dark-mode .btn.alt-dm:hover {
    background-color: rgba(0, 0, 0, .2)
}

.dark-mode .btn.alt-dm:focus {
    color: inherit;
    background-color: rgba(0, 0, 0, .15);
    border-color: inherit;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .025);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .025);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .025);
    outline-color: transparent
}

.dark-mode .btn.alt-dm.active {
    color: inherit;
    background-color: rgba(0, 0, 0, .15);
    border-color: inherit;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .025);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .025);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .025);
    outline-color: transparent
}

.dark-mode .btn.alt-dm:focus:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, .2)
}

.dark-mode .btn.alt-dm.active:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, .2)
}

.dark-mode .btn.alt-dm.disabled,
.dark-mode .btn.alt-dm.disabled:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, .15);
    border-color: rgba(0, 0, 0, .05);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.alt-dm:disabled {
    color: inherit;
    background-color: rgba(0, 0, 0, .15);
    border-color: rgba(0, 0, 0, .05);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.alt-dm.disabled:focus {
    color: inherit;
    background-color: rgba(0, 0, 0, .15);
    border-color: rgba(0, 0, 0, .05);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn-sm {
    height: 2.6rem;
    line-height: 2.6rem;
    padding: 0 1rem;
    font-size: 1.2rem
}

.btn-lg {
    height: 4rem;
    line-height: 4rem;
    padding: 0 2rem;
    font-size: 1.8rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn.btn-link,
.btn.btn-link:hover {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn.btn-link:hover {
    color: #45a3fb
}

.btn.btn-link:focus {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    outline-color: transparent
}

.btn.btn-link.active {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    outline-color: transparent
}

.btn.btn-link:focus:hover,
.dark-mode .btn.btn-link:focus:hover {
    color: #45a3fb;
    background-color: transparent
}

.btn.btn-link.active:hover,
.dark-mode .btn.btn-link.active:hover {
    color: #45a3fb;
    background-color: transparent
}

.dark-mode .btn.btn-link,
.dark-mode .btn.btn-link:hover {
    background-color: transparent;
    border-color: transparent;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.dark-mode .btn.btn-link {
    color: #1890ff;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.dark-mode .btn.btn-link:hover {
    color: #45a3fb
}

.dark-mode .btn.btn-link:focus {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    outline-color: transparent
}

.dark-mode .btn.btn-link.active {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    outline-color: transparent
}

.btn.btn-link.disabled,
.btn.btn-link.disabled:hover {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-link:disabled {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-link.disabled:focus {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-link.disabled,
.dark-mode .btn.btn-link.disabled:hover {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-link:disabled {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-link.disabled:focus {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-primary,
.btn.btn-primary:hover {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .05)
}

.btn.btn-primary:hover {
    background-color: #45a3fb;
    border-color: #45a3fb
}

.btn.btn-primary:focus {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -moz-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    outline-color: transparent
}

.btn.btn-primary.active {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -moz-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    outline-color: transparent
}

.btn.btn-primary:focus:hover,
.dark-mode .btn.btn-primary:focus:hover {
    color: #fff;
    background-color: #45a3fb
}

.btn.btn-primary.active:hover,
.dark-mode .btn.btn-primary.active:hover {
    color: #fff;
    background-color: #45a3fb
}

.dark-mode .btn.btn-primary,
.dark-mode .btn.btn-primary:hover {
    color: #fff;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .1)
}

.dark-mode .btn.btn-primary {
    background-color: #1890ff;
    border-color: #1890ff;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.dark-mode .btn.btn-primary:hover {
    background-color: #45a3fb;
    border-color: #45a3fb
}

.dark-mode .btn.btn-primary:focus {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -moz-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    outline-color: transparent
}

.dark-mode .btn.btn-primary.active {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -moz-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    outline-color: transparent
}

.btn.btn-primary.disabled,
.btn.btn-primary.disabled:hover {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-primary:disabled {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-primary.disabled:focus {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-primary.disabled,
.dark-mode .btn.btn-primary.disabled:hover {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-primary:disabled {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-primary.disabled:focus {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-success,
.btn.btn-success:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .05)
}

.btn.btn-success:hover {
    background-color: #81eea8;
    border-color: #81eea8
}

.btn.btn-success:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -moz-box-shadow: 0 0 0 3px rgba(11, 232, 129, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(11, 232, 129, .3);
    box-shadow: 0 0 0 3px rgba(11, 232, 129, .3);
    outline-color: transparent
}

.btn.btn-success.active {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -moz-box-shadow: 0 0 0 3px rgba(11, 232, 129, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(11, 232, 129, .3);
    box-shadow: 0 0 0 3px rgba(11, 232, 129, .3);
    outline-color: transparent
}

.btn.btn-success:focus:hover,
.dark-mode .btn.btn-success:focus:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #81eea8
}

.btn.btn-success.active:hover,
.dark-mode .btn.btn-success.active:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #81eea8
}

.dark-mode .btn.btn-success,
.dark-mode .btn.btn-success:hover {
    color: rgba(0, 0, 0, .85);
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .1)
}

.dark-mode .btn.btn-success {
    background-color: #0be881;
    border-color: #0be881;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.dark-mode .btn.btn-success:hover {
    background-color: #81eea8;
    border-color: #81eea8
}

.dark-mode .btn.btn-success:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -moz-box-shadow: 0 0 0 3px rgba(11, 232, 129, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(11, 232, 129, .3);
    box-shadow: 0 0 0 3px rgba(11, 232, 129, .3);
    outline-color: transparent
}

.dark-mode .btn.btn-success.active {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -moz-box-shadow: 0 0 0 3px rgba(11, 232, 129, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(11, 232, 129, .3);
    box-shadow: 0 0 0 3px rgba(11, 232, 129, .3);
    outline-color: transparent
}

.btn.btn-success.disabled,
.btn.btn-success.disabled:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-success:disabled {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-success.disabled:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-success.disabled,
.dark-mode .btn.btn-success.disabled:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-success:disabled {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-success.disabled:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-secondary,
.btn.btn-secondary:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .05)
}

.btn.btn-secondary:hover {
    background-color: #fbd35b;
    border-color: #fbd35b
}

.btn.btn-secondary:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312;
    -moz-box-shadow: 0 0 0 3px rgba(255, 195, 18, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(255, 195, 18, .3);
    box-shadow: 0 0 0 3px rgba(255, 195, 18, .3);
    outline-color: transparent
}

.btn.btn-secondary.active {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312;
    -moz-box-shadow: 0 0 0 3px rgba(255, 195, 18, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(255, 195, 18, .3);
    box-shadow: 0 0 0 3px rgba(255, 195, 18, .3);
    outline-color: transparent
}

.btn.btn-secondary:focus:hover,
.dark-mode .btn.btn-secondary:focus:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #fbd35b
}

.btn.btn-secondary.active:hover,
.dark-mode .btn.btn-secondary.active:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #fbd35b
}

.dark-mode .btn.btn-secondary,
.dark-mode .btn.btn-secondary:hover {
    color: rgba(0, 0, 0, .85);
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .1)
}

.dark-mode .btn.btn-secondary {
    background-color: #ffc312;
    border-color: #ffc312;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.dark-mode .btn.btn-secondary:hover {
    background-color: #fbd35b;
    border-color: #fbd35b
}

.dark-mode .btn.btn-secondary:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312;
    -moz-box-shadow: 0 0 0 3px rgba(255, 195, 18, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(255, 195, 18, .3);
    box-shadow: 0 0 0 3px rgba(255, 195, 18, .3);
    outline-color: transparent
}

.dark-mode .btn.btn-secondary.active {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312;
    -moz-box-shadow: 0 0 0 3px rgba(255, 195, 18, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(255, 195, 18, .3);
    box-shadow: 0 0 0 3px rgba(255, 195, 18, .3);
    outline-color: transparent
}

.btn.btn-secondary.disabled,
.btn.btn-secondary.disabled:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-secondary:disabled {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-secondary.disabled:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-secondary.disabled,
.dark-mode .btn.btn-secondary.disabled:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-secondary:disabled {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-secondary.disabled:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-danger,
.btn.btn-danger:hover {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .05)
}

.btn.btn-danger:hover {
    background-color: #ed586c;
    border-color: #ed586c
}

.btn.btn-danger:focus {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -moz-box-shadow: 0 0 0 3px rgba(245, 34, 45, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(245, 34, 45, .3);
    box-shadow: 0 0 0 3px rgba(245, 34, 45, .3);
    outline-color: transparent
}

.btn.btn-danger.active {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -moz-box-shadow: 0 0 0 3px rgba(245, 34, 45, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(245, 34, 45, .3);
    box-shadow: 0 0 0 3px rgba(245, 34, 45, .3);
    outline-color: transparent
}

.btn.btn-danger:focus:hover,
.dark-mode .btn.btn-danger:focus:hover {
    color: #fff;
    background-color: #ed586c
}

.btn.btn-danger.active:hover,
.dark-mode .btn.btn-danger.active:hover {
    color: #fff;
    background-color: #ed586c
}

.dark-mode .btn.btn-danger,
.dark-mode .btn.btn-danger:hover {
    color: #fff;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .1)
}

.dark-mode .btn.btn-danger {
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.dark-mode .btn.btn-danger:hover {
    background-color: #ed586c;
    border-color: #ed586c
}

.dark-mode .btn.btn-danger:focus {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -moz-box-shadow: 0 0 0 3px rgba(245, 34, 45, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(245, 34, 45, .3);
    box-shadow: 0 0 0 3px rgba(245, 34, 45, .3);
    outline-color: transparent
}

.dark-mode .btn.btn-danger.active {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -moz-box-shadow: 0 0 0 3px rgba(245, 34, 45, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(245, 34, 45, .3);
    box-shadow: 0 0 0 3px rgba(245, 34, 45, .3);
    outline-color: transparent
}

.btn.btn-danger.disabled,
.btn.btn-danger.disabled:hover {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-danger:disabled {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.btn.btn-danger.disabled:focus {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-danger.disabled,
.dark-mode .btn.btn-danger.disabled:hover {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-danger:disabled {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .btn.btn-danger.disabled:focus {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

ul {
    list-style: circle inside
}

ol {
    list-style: decimal inside
}

ol,
ul {
    padding-left: 0;
    margin-top: 0
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin: 1.5rem 0 1.5rem 3rem
}

li {
    margin-bottom: 1rem
}

.code,
code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

.code,
kbd {
    font-size: 1.2rem;
    line-height: 1.5;
    padding: 1px .5rem;
    margin: 0 1px;
    color: rgba(0, 0, 0, .85);
    background-color: #ecf0f1;
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 2px
}

.dark-mode .code {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(0, 0, 0, .1);
    border-color: rgba(0, 0, 0, .15)
}

kbd {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.dark-mode kbd {
    color: rgba(255, 255, 255, .8);
    background-color: transparent;
    border-color: rgba(255, 255, 255, .2)
}

.table-responsive {
    overflow-x: auto
}

.table-responsive .table td,
.table-responsive .table th {
    white-space: nowrap
}

.table {
    width: 100%
}

.table thead tr {
    border-bottom: 2px solid #dadfe1
}

.dark-mode .table tfoot tr,
.dark-mode .table thead tr,
.dark-mode .table-bordered,
.dark-mode .table-outer-bordered {
    border-color: #47494d
}

.table tfoot tr {
    border-top: 2px solid #dadfe1
}

.table tbody tr:not(:last-child) {
    border-bottom: 1px solid #dadfe1
}

.dark-mode .table tbody tr:not(:last-child),
.dark-mode .table-bordered td:not(:last-child),
.dark-mode .table-bordered th:not(:last-child),
.dark-mode .table-inner-bordered td:not(:last-child),
.dark-mode .table-inner-bordered th:not(:last-child) {
    border-color: #47494d
}

.table th {
    font-weight: 700
}

.table td,
.table th {
    text-align: left
}

.table td,
.table th {
    padding: 1.2rem 1.5rem
}

.form-row.row-eq-spacing>[class^=col]:first-child,
.table-no-outer-padding td:first-child,
.table-no-outer-padding th:first-child {
    padding-left: 0
}

.form-row.row-eq-spacing>[class^=col]:last-child,
.table-no-outer-padding td:last-child,
.table-no-outer-padding th:last-child {
    padding-right: 0
}

.table-bordered,
.table-outer-bordered {
    border: 1px solid #dadfe1
}

.table-bordered td:not(:last-child),
.table-bordered th:not(:last-child),
.table-inner-bordered td:not(:last-child),
.table-inner-bordered th:not(:last-child) {
    border-right: 1px solid #dadfe1
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: rgba(0, 0, 0, .05)
}

.dark-mode .table-hover tbody tr:hover td,
.dark-mode .table-hover tbody tr:hover th {
    background-color: rgba(0, 0, 0, .2)
}

.table-striped tbody tr:nth-child(odd) {
    background-color: #ecf0f1
}

.dark-mode .table-striped tbody tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, .15)
}

.table tbody tr.table-primary,
.table-bordered tbody tr.table-primary,
.table-inner-bordered tbody tr.table-primary,
.table-striped tbody tr.table-primary {
    color: rgba(0, 0, 0, .85);
    background-color: #cfe7fd;
    border-color: #45a3fb
}

.table tbody tr.table-danger td,
.table tbody tr.table-danger th,
.table tbody tr.table-primary td,
.table tbody tr.table-primary th,
.table tbody tr.table-secondary td,
.table tbody tr.table-secondary th,
.table tbody tr.table-success td,
.table tbody tr.table-success th,
.table-bordered tbody tr.table-danger td,
.table-bordered tbody tr.table-danger th,
.table-bordered tbody tr.table-primary td,
.table-bordered tbody tr.table-primary th,
.table-bordered tbody tr.table-secondary td,
.table-bordered tbody tr.table-secondary th,
.table-bordered tbody tr.table-success td,
.table-bordered tbody tr.table-success th,
.table-inner-bordered tbody tr.table-danger td,
.table-inner-bordered tbody tr.table-danger th,
.table-inner-bordered tbody tr.table-primary td,
.table-inner-bordered tbody tr.table-primary th,
.table-inner-bordered tbody tr.table-secondary td,
.table-inner-bordered tbody tr.table-secondary th,
.table-inner-bordered tbody tr.table-success td,
.table-inner-bordered tbody tr.table-success th {
    border-color: #bababc
}

.table tbody tr.table-success,
.table-bordered tbody tr.table-success,
.table-inner-bordered tbody tr.table-success,
.table-striped tbody tr.table-success {
    color: rgba(0, 0, 0, .85);
    background-color: #d5f9e5;
    border-color: #0be881
}

.table tbody tr.table-secondary,
.table-bordered tbody tr.table-secondary,
.table-inner-bordered tbody tr.table-secondary,
.table-striped tbody tr.table-secondary {
    color: rgba(0, 0, 0, .85);
    background-color: #fef1ce;
    border-color: #ffc312
}

.table tbody tr.table-danger,
.table-bordered tbody tr.table-danger,
.table-inner-bordered tbody tr.table-danger,
.table-striped tbody tr.table-danger {
    color: rgba(0, 0, 0, .85);
    background-color: #f8d0d9;
    border-color: #ed586c
}

.dark-mode .table tbody tr.table-primary,
.dark-mode .table-bordered tbody tr.table-primary,
.dark-mode .table-inner-bordered tbody tr.table-primary,
.dark-mode .table-striped tbody tr.table-primary {
    color: #1890ff;
    background-color: #172636;
    border-color: #0d518f
}

.dark-mode .table tbody tr.table-danger td,
.dark-mode .table tbody tr.table-danger th,
.dark-mode .table tbody tr.table-primary td,
.dark-mode .table tbody tr.table-primary th,
.dark-mode .table tbody tr.table-secondary td,
.dark-mode .table tbody tr.table-secondary th,
.dark-mode .table tbody tr.table-success td,
.dark-mode .table tbody tr.table-success th,
.dark-mode .table-bordered tbody tr.table-danger td,
.dark-mode .table-bordered tbody tr.table-danger th,
.dark-mode .table-bordered tbody tr.table-primary td,
.dark-mode .table-bordered tbody tr.table-primary th,
.dark-mode .table-bordered tbody tr.table-secondary td,
.dark-mode .table-bordered tbody tr.table-secondary th,
.dark-mode .table-bordered tbody tr.table-success td,
.dark-mode .table-bordered tbody tr.table-success th,
.dark-mode .table-inner-bordered tbody tr.table-danger td,
.dark-mode .table-inner-bordered tbody tr.table-danger th,
.dark-mode .table-inner-bordered tbody tr.table-primary td,
.dark-mode .table-inner-bordered tbody tr.table-primary th,
.dark-mode .table-inner-bordered tbody tr.table-secondary td,
.dark-mode .table-inner-bordered tbody tr.table-secondary th,
.dark-mode .table-inner-bordered tbody tr.table-success td,
.dark-mode .table-inner-bordered tbody tr.table-success th {
    border-color: #47494d
}

.dark-mode .table tbody tr.table-success,
.dark-mode .table-bordered tbody tr.table-success,
.dark-mode .table-inner-bordered tbody tr.table-success,
.dark-mode .table-striped tbody tr.table-success {
    color: #0be881;
    background-color: #17312a;
    border-color: #0d8951
}

.dark-mode .table tbody tr.table-secondary,
.dark-mode .table-bordered tbody tr.table-secondary,
.dark-mode .table-inner-bordered tbody tr.table-secondary,
.dark-mode .table-striped tbody tr.table-secondary {
    color: #ffc312;
    background-color: #302c1d;
    border-color: #8c7010
}

.dark-mode .table tbody tr.table-danger,
.dark-mode .table-bordered tbody tr.table-danger,
.dark-mode .table-inner-bordered tbody tr.table-danger,
.dark-mode .table-striped tbody tr.table-danger {
    color: #ff4d4f;
    background-color: #301923;
    border-color: #8c0e2e
}

label {
    display: inline-block;
    margin-bottom: .6rem
}

label.required:after {
    content: "*";
    color: #ff4d4f;
    margin-left: .3rem
}

.form-group,
.form-row {
    margin-bottom: 2rem
}

.form-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.form-row.row-eq-spacing>[class^=col] {
    padding-left: 1rem;
    padding-right: 1rem
}

@media (min-width:577px) {
    .form-row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col] {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .form-row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]:first-child {
        padding-left: 0
    }

    .form-row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]:last-child {
        padding-right: 0
    }
}

@media (max-width:576px) {
    .form-row.row-eq-spacing-sm:not(.row-eq-spacing)>[class^=col]:not(:last-child) {
        margin-bottom: 2rem
    }
}

@media (min-width:769px) {
    .form-row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col] {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .form-row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:first-child {
        padding-left: 0
    }

    .form-row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:last-child {
        padding-right: 0
    }
}

@media (max-width:768px) {
    .form-row.row-eq-spacing-md:not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:not(:last-child) {
        margin-bottom: 2rem
    }
}

@media (min-width:993px) {
    .form-row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col] {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .form-row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:first-child {
        padding-left: 0
    }

    .form-row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:last-child {
        padding-right: 0
    }
}

@media (max-width:992px) {
    .form-row.row-eq-spacing-lg:not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:not(:last-child) {
        margin-bottom: 2rem
    }
}

@media (min-width:1201px) {
    .form-row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col] {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .form-row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:first-child {
        padding-left: 0
    }

    .form-row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:last-child {
        padding-right: 0
    }
}

@media (max-width:1200px) {
    .form-row.row-eq-spacing-xl:not(.row-eq-spacing-lg):not(.row-eq-spacing-md):not(.row-eq-spacing-sm):not(.row-eq-spacing)>[class^=col]:not(:last-child) {
        margin-bottom: 2rem
    }
}

.form-text,
.invalid-feedback {
    font-size: 1.4rem;
    padding-top: .8rem;
    padding-bottom: .8rem
}

.form-text {
    color: rgba(0, 0, 0, .7);
    border-bottom: 1px dotted rgba(0, 0, 0, .2)
}

.dark-mode .form-text {
    color: rgba(255, 255, 255, .65);
    border-color: rgba(255, 255, 255, .1)
}

.invalid-feedback {
    color: #ff4d4f
}

.dark-mode .invalid-feedback {
    color: #ff4d4f
}

.form-inline,
.form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center
}

.form-inline .form-group {
    width: 100%
}

.form-inline .form-group>.form-control,
.form-inline .form-group>.input-group,
.form-inline>.form-control,
.form-inline>.input-group {
    position: relative;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0
}

.form-inline .form-group>.custom-control,
.form-inline .form-group>.form-text,
.form-inline .form-group>.invalid-feedback,
.form-inline .form-group>label,
.form-inline>.custom-control,
.form-inline>.form-text,
.form-inline>.invalid-feedback,
.form-inline>label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-width: 0
}

.form-inline .form-group>.form-text,
.form-inline .form-group>.invalid-feedback,
.form-inline .form-group>label,
.form-inline>.form-text,
.form-inline>.invalid-feedback,
.form-inline>label {
    margin: 0;
    padding: 0;
    border: 0;
    white-space: nowrap
}

.form-inline .form-group>.btn:not(:last-child),
.form-inline .form-group>.form-control:not(:last-child),
.form-inline .form-group>.form-text:not(:last-child),
.form-inline .form-group>.input-group:not(:last-child),
.form-inline .form-group>.invalid-feedback:not(:last-child),
.form-inline .form-group>a.btn:not(:last-child),
.form-inline .form-group>label:not(:last-child),
.form-inline>.btn:not(:last-child),
.form-inline>.form-control:not(:last-child),
.form-inline>.form-text:not(:last-child),
.form-inline>.input-group:not(:last-child),
.form-inline>.invalid-feedback:not(:last-child),
.form-inline>a.btn:not(:last-child),
.form-inline>label:not(:last-child) {
    margin-right: 1rem
}

.form-inline .form-group>.custom-control,
.form-inline>.custom-control {
    margin-right: 1rem
}

@media (min-width:577px) {

    .form-inline-sm,
    .form-inline-sm .form-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-align: center;
        align-items: center
    }

    .form-inline-sm .form-group {
        width: 100%
    }

    .form-inline-sm .form-group>.form-control,
    .form-inline-sm .form-group>.input-group,
    .form-inline-sm>.form-control,
    .form-inline-sm>.input-group {
        position: relative;
        -ms-flex: 1 1 0%;
        flex: 1 1 0%;
        min-width: 0
    }

    .form-inline-sm .form-group>.custom-control,
    .form-inline-sm .form-group>.form-text,
    .form-inline-sm .form-group>.invalid-feedback,
    .form-inline-sm .form-group>label,
    .form-inline-sm>.custom-control,
    .form-inline-sm>.form-text,
    .form-inline-sm>.invalid-feedback,
    .form-inline-sm>label {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        min-width: 0
    }

    .form-inline-sm .form-group>.form-text,
    .form-inline-sm .form-group>.invalid-feedback,
    .form-inline-sm .form-group>label,
    .form-inline-sm>.form-text,
    .form-inline-sm>.invalid-feedback,
    .form-inline-sm>label {
        margin: 0;
        padding: 0;
        border: 0;
        white-space: nowrap
    }

    .form-inline-sm .form-group>.btn:not(:last-child),
    .form-inline-sm .form-group>.form-control:not(:last-child),
    .form-inline-sm .form-group>.form-text:not(:last-child),
    .form-inline-sm .form-group>.input-group:not(:last-child),
    .form-inline-sm .form-group>.invalid-feedback:not(:last-child),
    .form-inline-sm .form-group>a.btn:not(:last-child),
    .form-inline-sm .form-group>label:not(:last-child),
    .form-inline-sm>.btn:not(:last-child),
    .form-inline-sm>.form-control:not(:last-child),
    .form-inline-sm>.form-text:not(:last-child),
    .form-inline-sm>.input-group:not(:last-child),
    .form-inline-sm>.invalid-feedback:not(:last-child),
    .form-inline-sm>a.btn:not(:last-child),
    .form-inline-sm>label:not(:last-child) {
        margin-right: 1rem
    }

    .form-inline-sm .form-group>.custom-control,
    .form-inline-sm>.custom-control {
        margin-right: 1rem
    }
}

@media (max-width:576px) {

    .form-inline-sm .form-group>.btn:not(:last-child),
    .form-inline-sm .form-group>.form-control:not(:last-child),
    .form-inline-sm .form-group>.input-group:not(:last-child),
    .form-inline-sm .form-group>a.btn:not(:last-child),
    .form-inline-sm>.btn:not(:last-child),
    .form-inline-sm>.form-control:not(:last-child),
    .form-inline-sm>.input-group:not(:last-child),
    .form-inline-sm>a.btn:not(:last-child) {
        margin-bottom: 2rem
    }

    .form-inline-sm .form-group>.custom-control,
    .form-inline-sm>.custom-control {
        margin-bottom: 2rem
    }
}

@media (min-width:769px) {

    .form-inline-md,
    .form-inline-md .form-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-align: center;
        align-items: center
    }

    .form-inline-md .form-group {
        width: 100%
    }

    .form-inline-md .form-group>.form-control,
    .form-inline-md .form-group>.input-group,
    .form-inline-md>.form-control,
    .form-inline-md>.input-group {
        position: relative;
        -ms-flex: 1 1 0%;
        flex: 1 1 0%;
        min-width: 0
    }

    .form-inline-md .form-group>.custom-control,
    .form-inline-md .form-group>.form-text,
    .form-inline-md .form-group>.invalid-feedback,
    .form-inline-md .form-group>label,
    .form-inline-md>.custom-control,
    .form-inline-md>.form-text,
    .form-inline-md>.invalid-feedback,
    .form-inline-md>label {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        min-width: 0
    }

    .form-inline-md .form-group>.form-text,
    .form-inline-md .form-group>.invalid-feedback,
    .form-inline-md .form-group>label,
    .form-inline-md>.form-text,
    .form-inline-md>.invalid-feedback,
    .form-inline-md>label {
        margin: 0;
        padding: 0;
        border: 0;
        white-space: nowrap
    }

    .form-inline-md .form-group>.btn:not(:last-child),
    .form-inline-md .form-group>.form-control:not(:last-child),
    .form-inline-md .form-group>.form-text:not(:last-child),
    .form-inline-md .form-group>.input-group:not(:last-child),
    .form-inline-md .form-group>.invalid-feedback:not(:last-child),
    .form-inline-md .form-group>a.btn:not(:last-child),
    .form-inline-md .form-group>label:not(:last-child),
    .form-inline-md>.btn:not(:last-child),
    .form-inline-md>.form-control:not(:last-child),
    .form-inline-md>.form-text:not(:last-child),
    .form-inline-md>.input-group:not(:last-child),
    .form-inline-md>.invalid-feedback:not(:last-child),
    .form-inline-md>a.btn:not(:last-child),
    .form-inline-md>label:not(:last-child) {
        margin-right: 1rem
    }

    .form-inline-md .form-group>.custom-control,
    .form-inline-md>.custom-control {
        margin-right: 1rem
    }
}

@media (max-width:768px) {

    .form-inline-md .form-group>.btn:not(:last-child),
    .form-inline-md .form-group>.form-control:not(:last-child),
    .form-inline-md .form-group>.input-group:not(:last-child),
    .form-inline-md .form-group>a.btn:not(:last-child),
    .form-inline-md>.btn:not(:last-child),
    .form-inline-md>.form-control:not(:last-child),
    .form-inline-md>.input-group:not(:last-child),
    .form-inline-md>a.btn:not(:last-child) {
        margin-bottom: 2rem
    }

    .form-inline-md .form-group>.custom-control,
    .form-inline-md>.custom-control {
        margin-bottom: 2rem
    }
}

@media (min-width:993px) {

    .form-inline-lg,
    .form-inline-lg .form-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-align: center;
        align-items: center
    }

    .form-inline-lg .form-group {
        width: 100%
    }

    .form-inline-lg .form-group>.form-control,
    .form-inline-lg .form-group>.input-group,
    .form-inline-lg>.form-control,
    .form-inline-lg>.input-group {
        position: relative;
        -ms-flex: 1 1 0%;
        flex: 1 1 0%;
        min-width: 0
    }

    .form-inline-lg .form-group>.custom-control,
    .form-inline-lg .form-group>.form-text,
    .form-inline-lg .form-group>.invalid-feedback,
    .form-inline-lg .form-group>label,
    .form-inline-lg>.custom-control,
    .form-inline-lg>.form-text,
    .form-inline-lg>.invalid-feedback,
    .form-inline-lg>label {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        min-width: 0
    }

    .form-inline-lg .form-group>.form-text,
    .form-inline-lg .form-group>.invalid-feedback,
    .form-inline-lg .form-group>label,
    .form-inline-lg>.form-text,
    .form-inline-lg>.invalid-feedback,
    .form-inline-lg>label {
        margin: 0;
        padding: 0;
        border: 0;
        white-space: nowrap
    }

    .form-inline-lg .form-group>.btn:not(:last-child),
    .form-inline-lg .form-group>.form-control:not(:last-child),
    .form-inline-lg .form-group>.form-text:not(:last-child),
    .form-inline-lg .form-group>.input-group:not(:last-child),
    .form-inline-lg .form-group>.invalid-feedback:not(:last-child),
    .form-inline-lg .form-group>a.btn:not(:last-child),
    .form-inline-lg .form-group>label:not(:last-child),
    .form-inline-lg>.btn:not(:last-child),
    .form-inline-lg>.form-control:not(:last-child),
    .form-inline-lg>.form-text:not(:last-child),
    .form-inline-lg>.input-group:not(:last-child),
    .form-inline-lg>.invalid-feedback:not(:last-child),
    .form-inline-lg>a.btn:not(:last-child),
    .form-inline-lg>label:not(:last-child) {
        margin-right: 1rem
    }

    .form-inline-lg .form-group>.custom-control,
    .form-inline-lg>.custom-control {
        margin-right: 1rem
    }
}

@media (max-width:992px) {

    .form-inline-lg .form-group>.btn:not(:last-child),
    .form-inline-lg .form-group>.form-control:not(:last-child),
    .form-inline-lg .form-group>.input-group:not(:last-child),
    .form-inline-lg .form-group>a.btn:not(:last-child),
    .form-inline-lg>.btn:not(:last-child),
    .form-inline-lg>.form-control:not(:last-child),
    .form-inline-lg>.input-group:not(:last-child),
    .form-inline-lg>a.btn:not(:last-child) {
        margin-bottom: 2rem
    }

    .form-inline-lg .form-group>.custom-control,
    .form-inline-lg>.custom-control {
        margin-bottom: 2rem
    }
}

@media (min-width:1201px) {

    .form-inline-xl,
    .form-inline-xl .form-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-align: center;
        align-items: center
    }

    .form-inline-xl .form-group {
        width: 100%
    }

    .form-inline-xl .form-group>.form-control,
    .form-inline-xl .form-group>.input-group,
    .form-inline-xl>.form-control,
    .form-inline-xl>.input-group {
        position: relative;
        -ms-flex: 1 1 0%;
        flex: 1 1 0%;
        min-width: 0
    }

    .form-inline-xl .form-group>.custom-control,
    .form-inline-xl .form-group>.form-text,
    .form-inline-xl .form-group>.invalid-feedback,
    .form-inline-xl .form-group>label,
    .form-inline-xl>.custom-control,
    .form-inline-xl>.form-text,
    .form-inline-xl>.invalid-feedback,
    .form-inline-xl>label {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        min-width: 0
    }

    .form-inline-xl .form-group>.form-text,
    .form-inline-xl .form-group>.invalid-feedback,
    .form-inline-xl .form-group>label,
    .form-inline-xl>.form-text,
    .form-inline-xl>.invalid-feedback,
    .form-inline-xl>label {
        margin: 0;
        padding: 0;
        border: 0;
        white-space: nowrap
    }

    .form-inline-xl .form-group>.btn:not(:last-child),
    .form-inline-xl .form-group>.form-control:not(:last-child),
    .form-inline-xl .form-group>.form-text:not(:last-child),
    .form-inline-xl .form-group>.input-group:not(:last-child),
    .form-inline-xl .form-group>.invalid-feedback:not(:last-child),
    .form-inline-xl .form-group>a.btn:not(:last-child),
    .form-inline-xl .form-group>label:not(:last-child),
    .form-inline-xl>.btn:not(:last-child),
    .form-inline-xl>.form-control:not(:last-child),
    .form-inline-xl>.form-text:not(:last-child),
    .form-inline-xl>.input-group:not(:last-child),
    .form-inline-xl>.invalid-feedback:not(:last-child),
    .form-inline-xl>a.btn:not(:last-child),
    .form-inline-xl>label:not(:last-child) {
        margin-right: 1rem
    }

    .form-inline-xl .form-group>.custom-control,
    .form-inline-xl>.custom-control {
        margin-right: 1rem
    }
}

@media (max-width:1200px) {

    .form-inline-xl .form-group>.btn:not(:last-child),
    .form-inline-xl .form-group>.form-control:not(:last-child),
    .form-inline-xl .form-group>.input-group:not(:last-child),
    .form-inline-xl .form-group>a.btn:not(:last-child),
    .form-inline-xl>.btn:not(:last-child),
    .form-inline-xl>.form-control:not(:last-child),
    .form-inline-xl>.input-group:not(:last-child),
    .form-inline-xl>a.btn:not(:last-child) {
        margin-bottom: 2rem
    }

    .form-inline-xl .form-group>.custom-control,
    .form-inline-xl>.custom-control {
        margin-bottom: 2rem
    }
}

.form-control,
.form-control:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #fff;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-control {
    display: block;
    width: 100%;
    height: 3.2rem;
    padding-left: .8rem;
    padding-right: .8rem;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .4rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-control-lg,
.form-control-sm {
    height: 2.6rem;
    padding-left: .8rem;
    padding-right: .8rem;
    font-size: 1.2rem
}

.form-control-lg {
    height: 4rem;
    font-size: 1.8rem
}

textarea.form-control {
    max-width: 100%;
    min-height: 10rem;
    padding: .8rem;
    line-height: 1.5
}

textarea.form-control-sm {
    min-height: 8rem;
    padding: .8rem
}

textarea.form-control-lg {
    min-height: 14rem;
    padding: .8rem
}

.form-control:hover {
    border-color: rgba(0, 0, 0, .3)
}

.form-control:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #fff;
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    outline: 0
}

::-webkit-input-placeholder {
    color: rgba(0, 0, 0, .35)
}

:-ms-input-placeholder {
    color: rgba(0, 0, 0, .35)
}

::placeholder {
    color: rgba(0, 0, 0, .35)
}

.form-control.disabled,
.form-control.disabled:hover {
    cursor: not-allowed;
    opacity: .6;
    color: rgba(0, 0, 0, .85);
    background-color: #ecf0f1;
    border-color: rgba(0, 0, 0, .2)
}

.form-control:disabled,
.form-control:disabled:hover {
    cursor: not-allowed;
    opacity: .6;
    color: rgba(0, 0, 0, .85);
    background-color: #ecf0f1;
    border-color: rgba(0, 0, 0, .2)
}

.dark-mode .form-control,
.dark-mode .form-control:hover {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: rgba(0, 0, 0, .3);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.dark-mode .form-control:hover {
    border-color: rgba(0, 0, 0, .4)
}

.dark-mode .form-control:focus {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    outline: 0
}

.dark-mode .form-group.is-invalid .form-control::-webkit-input-placeholder,
.dark-mode ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .2)
}

.dark-mode :-ms-input-placeholder {
    color: rgba(255, 255, 255, .2)
}

.dark-mode .form-group.is-invalid .form-control::placeholder,
.dark-mode ::placeholder {
    color: rgba(255, 255, 255, .2)
}

.dark-mode .form-control.disabled,
.dark-mode .form-control.disabled:hover {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(0, 0, 0, .15);
    border-color: rgba(0, 0, 0, .3)
}

.dark-mode .form-control:disabled,
.dark-mode .form-control:disabled:hover {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(0, 0, 0, .15);
    border-color: rgba(0, 0, 0, .3)
}

.dark-mode .form-control.alt-dm,
.dark-mode .form-control.alt-dm:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, .15);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.dark-mode .form-control.alt-dm {
    border-color: transparent
}

.dark-mode .form-control.alt-dm:hover {
    border-color: rgba(0, 0, 0, .1)
}

.dark-mode .form-control.alt-dm:focus {
    color: inherit;
    background-color: rgba(0, 0, 0, .15);
    border-color: inherit;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0
}

.dark-mode .form-control.alt-dm.is-invalid::-webkit-input-placeholder,
.dark-mode .form-control.alt-dm::-webkit-input-placeholder,
.dark-mode .form-group.is-invalid .form-control.alt-dm.is-invalid::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .5)
}

.dark-mode .form-control.alt-dm:-ms-input-placeholder,
.dark-mode .form-group.is-invalid .form-control.alt-dm.is-invalid:-ms-input-placeholder {
    color: rgba(255, 255, 255, .5)
}

.dark-mode .form-control.alt-dm.is-invalid::placeholder,
.dark-mode .form-control.alt-dm::placeholder,
.dark-mode .form-group.is-invalid .form-control.alt-dm.is-invalid::placeholder {
    color: rgba(255, 255, 255, .5)
}

.dark-mode .form-control.alt-dm.disabled,
.dark-mode .form-control.alt-dm.disabled:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, .3);
    border-color: transparent
}

.dark-mode .form-control.alt-dm:disabled,
.dark-mode .form-control.alt-dm:disabled:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, .3);
    border-color: transparent
}

.form-control.is-invalid,
.form-control.is-invalid:hover,
.form-group.is-invalid .form-control,
.form-group.is-invalid .form-control:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #fff;
    border-color: #ff4d4f;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-control.is-invalid:focus,
.form-group.is-invalid .form-control:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #fff;
    border-color: #f8d0d9;
    -moz-box-shadow: 0 0 0 2px rgba(245, 34, 45, .6);
    -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, .6);
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .6);
    outline: 0
}

.form-control.is-invalid::-webkit-input-placeholder,
.form-group.is-invalid .form-control::-webkit-input-placeholder {
    color: rgba(0, 0, 0, .35)
}

.form-control.is-invalid:-ms-input-placeholder,
.form-group.is-invalid .form-control:-ms-input-placeholder {
    color: rgba(0, 0, 0, .35)
}

.form-control.is-invalid::placeholder,
.form-group.is-invalid .form-control::placeholder {
    color: rgba(0, 0, 0, .35)
}

.dark-mode .form-control.is-invalid,
.dark-mode .form-control.is-invalid:hover,
.dark-mode .form-group.is-invalid .form-control,
.dark-mode .form-group.is-invalid .form-control:hover {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: #ff4d4f;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.dark-mode .form-control.is-invalid:focus,
.dark-mode .form-group.is-invalid .form-control:focus {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: #ff4d4f;
    -moz-box-shadow: 0 0 0 2px rgba(245, 34, 45, .3);
    -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, .3);
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .3);
    outline: 0
}

.dark-mode .form-control.is-invalid:-ms-input-placeholder,
.dark-mode .form-group.is-invalid .form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, .2)
}

.dark-mode .form-control.is-invalid::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .2)
}

.dark-mode .form-control.is-invalid::placeholder {
    color: rgba(255, 255, 255, .2)
}

.dark-mode .form-control.alt-dm.is-invalid,
.dark-mode .form-control.alt-dm.is-invalid:hover,
.dark-mode .form-group.is-invalid .form-control.alt-dm,
.dark-mode .form-group.is-invalid .form-control.alt-dm:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, .15);
    border-color: #ed586c;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.dark-mode .form-control.alt-dm.is-invalid:focus,
.dark-mode .form-group.is-invalid .form-control.alt-dm:focus {
    color: inherit;
    background-color: rgba(0, 0, 0, .15);
    border-color: #ed586c;
    -moz-box-shadow: 0 0 0 2px rgba(245, 34, 45, .3);
    -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, .3);
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .3);
    outline: 0
}

.dark-mode .form-control.alt-dm.is-invalid:-ms-input-placeholder {
    color: rgba(255, 255, 255, .5)
}

select.form-control:not([multiple]) {
    cursor: pointer;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAI9JREFUeNpiZACCyMjIBCDVD8SOy5cvv8BAAgDqNQBS+4G4EKh3ASPUsPlQ+Q+kGIpkmABUKJEJ6jIYAEnshyok1TAQ6AcZ6Ah1GdGG4jAM7DtGQgrQvU9ILSOxColVw0is7VA2QV8wkhA+DMQECSMJgc5AyDCcBhIwFG9aZSQxrRFM+IwkJGAGYnIRQIABACQuXCKovu2mAAAAAElFTkSuQmCC);
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) center;
    padding-right: 3.6rem
}

.dark-mode select.form-control:not([multiple])>option {
    color: rgba(255, 255, 255, .8);
    background-color: #191c20
}

.dark-mode select.form-control:not([multiple])>option:hover {
    color: rgba(255, 255, 255, .8);
    background-color: #1890ff
}

@media screen and (-ms-high-contrast:active),
(-ms-high-contrast:none) {
    select.form-control:not([multiple]) {
        background-image: none;
        background-size: auto;
        background-repeat: initial;
        background-position: 0 0;
        padding-right: 0
    }
}

select.form-control[multiple] {
    overflow: auto;
    height: auto;
    padding: .8rem
}

select.form-control-lg[multiple],
select.form-control-sm[multiple] {
    padding: .8rem
}

select.form-control[multiple]>option:not(:checked) {
    color: rgba(0, 0, 0, .85)
}

.dark-mode select.form-control[multiple]>option:not(:checked) {
    color: rgba(255, 255, 255, .8)
}

select.form-control[multiple]>option:disabled {
    color: rgba(0, 0, 0, .6)
}

.dark-mode select.form-control[multiple]>option:disabled {
    color: rgba(255, 255, 255, .6)
}

.custom-checkbox {
    display: block;
    position: relative;
    line-height: 1.8rem
}

.custom-checkbox input[type=checkbox],
.custom-radio input[type=radio],
.custom-switch input[type=checkbox] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0
}

.custom-checkbox label,
.custom-radio label {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 2.5rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.custom-checkbox label.blank {
    padding-left: 1.8rem
}

.custom-checkbox label:before {
    content: "";
    display: inline-block;
    position: absolute;
    height: 1.8rem;
    width: 1.8rem;
    top: 0;
    left: 0;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: .4rem
}

.custom-checkbox input[type=checkbox]:hover~label:before {
    background-color: #fff;
    border-color: rgba(0, 0, 0, .4)
}

.custom-checkbox input[type=checkbox]:focus~label:before {
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .6)
}

.custom-checkbox input[type=checkbox]:checked~label:before {
    background-color: #1890ff;
    border-color: #1890ff
}

.custom-checkbox input[type=checkbox]:checked:focus~label:before,
.dark-mode .custom-checkbox input[type=checkbox]:checked:focus~label:before {
    border-color: #1890ff;
    -moz-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 3px rgba(24, 144, 255, .3)
}

.dark-mode .custom-checkbox label:before {
    background-color: transparent;
    border-color: rgba(255, 255, 255, .15)
}

.dark-mode .custom-checkbox input[type=checkbox]:hover~label:before {
    background-color: transparent;
    border-color: rgba(255, 255, 255, .3)
}

.dark-mode .custom-checkbox input[type=checkbox]:focus~label:before {
    border-color: rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .3)
}

.dark-mode .custom-checkbox input[type=checkbox]:checked~label:before {
    background-color: #1890ff;
    border-color: #1890ff
}

.custom-checkbox label:after {
    content: "";
    position: absolute;
    display: none;
    left: .6rem;
    top: .3rem;
    width: .6rem;
    height: 1rem;
    border: solid #fff;
    border-width: 0 .2rem .2rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.dark-mode .custom-checkbox label:after {
    border-color: #fff
}

.custom-checkbox input[type=checkbox]:checked~label:after {
    display: block
}

.custom-checkbox input[type=checkbox]:disabled~label,
.custom-radio input[type=radio]:disabled~label,
.custom-switch input[type=checkbox]:disabled~label {
    opacity: .6;
    cursor: not-allowed
}

.custom-checkbox input[type=checkbox]:disabled~label:before,
.custom-checkbox input[type=checkbox]:hover:disabled~label:before {
    background-color: #fff;
    border-color: rgba(0, 0, 0, .3)
}

.custom-checkbox input[type=checkbox]:disabled:checked~label:before,
.custom-checkbox input[type=checkbox]:hover:disabled:checked~label:before {
    background-color: #1890ff;
    border-color: #1890ff
}

.dark-mode .custom-checkbox input[type=checkbox]:disabled~label:before,
.dark-mode .custom-checkbox input[type=checkbox]:hover:disabled~label:before {
    background-color: transparent;
    border-color: rgba(255, 255, 255, .15)
}

.dark-mode .custom-checkbox input[type=checkbox]:disabled:checked~label:before,
.dark-mode .custom-checkbox input[type=checkbox]:hover:disabled:checked~label:before {
    background-color: #1890ff;
    border-color: #1890ff
}

.custom-radio {
    display: block;
    position: relative;
    line-height: 1.6rem
}

.form-group .custom-radio {
    margin: .8rem 0 0
}

.custom-radio label.blank {
    padding-left: 1.6rem
}

.custom-radio label:before {
    content: "";
    display: inline-block;
    position: absolute;
    height: 1.6rem;
    width: 1.6rem;
    top: 0;
    left: 0;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 50%
}

.custom-radio input[type=radio]:hover~label:before {
    background-color: #fff;
    border-color: rgba(0, 0, 0, .4)
}

.custom-radio input[type=radio]:focus~label:before {
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .6)
}

.custom-radio input[type=radio]:checked~label:before {
    background-color: #1890ff;
    border-color: #1890ff
}

.custom-radio input[type=radio]:checked:focus~label:before,
.dark-mode .custom-radio input[type=radio]:checked:focus~label:before {
    border-color: #1890ff;
    -moz-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 3px rgba(24, 144, 255, .3)
}

.dark-mode .custom-radio label:before {
    background-color: transparent;
    border-color: rgba(255, 255, 255, .15)
}

.dark-mode .custom-radio input[type=radio]:hover~label:before {
    background-color: transparent;
    border-color: rgba(255, 255, 255, .3)
}

.dark-mode .custom-radio input[type=radio]:focus~label:before {
    border-color: rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .3)
}

.dark-mode .custom-radio input[type=radio]:checked~label:before {
    background-color: #1890ff;
    border-color: #1890ff
}

.custom-radio label:after {
    content: "";
    position: absolute;
    display: none;
    top: .5rem;
    left: .5rem;
    width: .6rem;
    height: .6rem;
    background-color: #fff;
    border-radius: 50%
}

.dark-mode .custom-radio label:after {
    border-color: #fff
}

.custom-radio input[type=radio]:checked~label:after {
    display: block
}

.custom-radio input[type=radio]:disabled~label:before,
.custom-radio input[type=radio]:hover:disabled~label:before {
    background-color: #fff;
    border-color: rgba(0, 0, 0, .3)
}

.custom-radio input[type=radio]:disabled:checked~label:before,
.custom-radio input[type=radio]:hover:disabled:checked~label:before {
    background-color: #1890ff;
    border-color: #1890ff
}

.dark-mode .custom-radio input[type=radio]:disabled~label:before,
.dark-mode .custom-radio input[type=radio]:hover:disabled~label:before {
    background-color: transparent;
    border-color: rgba(255, 255, 255, .15)
}

.dark-mode .custom-radio input[type=radio]:disabled:checked~label:before,
.dark-mode .custom-radio input[type=radio]:hover:disabled:checked~label:before {
    background-color: #1890ff;
    border-color: #1890ff
}

.custom-switch {
    display: block;
    position: relative;
    line-height: 2rem
}

.custom-switch label {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 4rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.custom-switch label.blank {
    padding-left: 3.2rem
}

.custom-switch label:before {
    content: "";
    display: inline-block;
    position: absolute;
    height: 2rem;
    width: 3.2rem;
    top: 0;
    left: 0;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 2rem
}

.custom-switch input[type=checkbox]:hover~label:before {
    background-color: #fff;
    border-color: rgba(0, 0, 0, .4)
}

.custom-switch input[type=checkbox]:focus~label:before {
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .6)
}

.custom-switch input[type=checkbox]:checked~label:before {
    background-color: #1890ff;
    border-color: #1890ff
}

.custom-switch input[type=checkbox]:checked:focus~label:before,
.dark-mode .custom-switch input[type=checkbox]:checked:focus~label:before {
    border-color: #1890ff;
    -moz-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 3px rgba(24, 144, 255, .3)
}

.dark-mode .custom-switch label:before {
    background-color: transparent;
    border-color: rgba(255, 255, 255, .15)
}

.dark-mode .custom-switch input[type=checkbox]:hover~label:before {
    background-color: transparent;
    border-color: rgba(255, 255, 255, .3)
}

.dark-mode .custom-switch input[type=checkbox]:focus~label:before {
    border-color: rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .3)
}

.dark-mode .custom-switch input[type=checkbox]:checked~label:before {
    background-color: #1890ff;
    border-color: #1890ff
}

.custom-switch label:after {
    content: "";
    position: absolute;
    height: 1.2rem;
    width: 1.2rem;
    top: .4rem;
    left: .5rem;
    background-color: rgba(0, 0, 0, .2);
    border: 0 solid rgba(0, 0, 0, .2);
    border-radius: 50%
}

.dark-mode .custom-switch label:after {
    background-color: rgba(255, 255, 255, .15);
    border-color: rgba(255, 255, 255, .15)
}

.custom-switch input[type=checkbox]:checked~label:after {
    top: .4rem;
    left: 1.5rem;
    background-color: #fff;
    border-color: #fff
}

.dark-mode .custom-switch input[type=checkbox]:checked~label:after {
    background-color: #fff;
    border-color: #fff
}

.custom-switch input[type=checkbox]:disabled~label:before,
.custom-switch input[type=checkbox]:hover:disabled~label:before {
    background-color: #fff;
    border-color: rgba(0, 0, 0, .3)
}

.custom-switch input[type=checkbox]:disabled:checked~label:before,
.custom-switch input[type=checkbox]:hover:disabled:checked~label:before {
    background-color: #1890ff;
    border-color: #1890ff
}

.dark-mode .custom-switch input[type=checkbox]:disabled~label:before,
.dark-mode .custom-switch input[type=checkbox]:hover:disabled~label:before {
    background-color: transparent;
    border-color: rgba(255, 255, 255, .15)
}

.dark-mode .custom-switch input[type=checkbox]:disabled:checked~label:before,
.dark-mode .custom-switch input[type=checkbox]:hover:disabled:checked~label:before {
    background-color: #1890ff;
    border-color: #1890ff
}

.custom-file {
    display: block;
    position: relative
}

.custom-file input[type=file] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    z-index: -1
}

.custom-file label,
.custom-file label:hover {
    color: rgba(0, 0, 0, .7);
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .05)
}

.custom-file label {
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 3.2rem;
    line-height: 3.2rem;
    padding: 0 1.5rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .4rem
}

.custom-file label:hover {
    background-color: #f7f7f7;
    border-color: rgba(0, 0, 0, .2)
}

.custom-file input[type=file]:focus~label {
    color: rgba(0, 0, 0, .7);
    background-color: #fff;
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .6);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .6)
}

.dark-mode .custom-file label,
.dark-mode .custom-file label:hover {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: transparent;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .1)
}

.dark-mode .custom-file label:hover {
    background-color: rgba(255, 255, 255, .1)
}

.dark-mode .custom-file input[type=file]:focus~label {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .3);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .3)
}

.custom-file input[type=file]:disabled~label,
.custom-file:hover input[type=file]:disabled~label {
    opacity: .6;
    cursor: not-allowed;
    color: rgba(0, 0, 0, .7);
    background-color: #fff;
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.dark-mode .custom-file input[type=file]:disabled~label,
.dark-mode .custom-file:hover input[type=file]:disabled~label {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: transparent;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.custom-file input[type=file]:disabled~.file-names {
    opacity: .6;
    pointer-events: none
}

.custom-file input[type=file].is-invalid~label,
.custom-file input[type=file].is-invalid~label:hover,
.form-group.is-invalid .custom-file label,
.form-group.is-invalid .custom-file label:hover {
    color: rgba(0, 0, 0, .7);
    background-color: #fff;
    border-color: #ff4d4f;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .05)
}

.custom-file input[type=file].is-invalid~label:hover,
.form-group.is-invalid .custom-file label:hover {
    background-color: #f7f7f7
}

.custom-file input[type=file].is-invalid:focus~label,
.form-group.is-invalid .custom-file input[type=file]:focus~label {
    color: rgba(0, 0, 0, .7);
    background-color: #fff;
    border-color: #f8d0d9;
    -moz-box-shadow: 0 0 0 2px rgba(245, 34, 45, .6);
    -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, .6);
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .6)
}

.custom-file input[type=file].is-invalid:focus:hover~label {
    color: rgba(0, 0, 0, .7);
    background-color: #f7f7f7
}

.dark-mode .custom-file input[type=file].is-invalid~label,
.dark-mode .custom-file input[type=file].is-invalid~label:hover,
.dark-mode .form-group.is-invalid .custom-file label,
.dark-mode .form-group.is-invalid .custom-file label:hover {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: #ff4d4f;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .1)
}

.dark-mode .custom-file input[type=file].is-invalid~label:hover,
.dark-mode .form-group.is-invalid .custom-file label:hover {
    background-color: rgba(255, 255, 255, .1)
}

.dark-mode .custom-file input[type=file].is-invalid:focus~label,
.dark-mode .form-group.is-invalid .custom-file input[type=file]:focus~label {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: #ff4d4f;
    -moz-box-shadow: 0 0 0 2px rgba(245, 34, 45, .3);
    -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, .3);
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .3)
}

.dark-mode .custom-file input[type=file].is-invalid:focus:hover~label {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .1)
}

.input-group {
    position: relative;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control {
    position: relative;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0
}

.input-group,
.input-group-append,
.input-group-prepend,
.input-group-text {
    display: -ms-flexbox;
    display: flex
}

.input-group-append>.btn,
.input-group-prepend>.btn {
    position: relative
}

.input-group-lg>.form-control,
.input-group-sm>.form-control {
    height: 2.6rem;
    padding-left: .8rem;
    padding-right: .8rem;
    font-size: 1.2rem
}

.input-group-lg>.form-control {
    height: 4rem;
    font-size: 1.8rem
}

.input-group-text {
    white-space: nowrap;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding-left: .8rem;
    padding-right: .8rem;
    color: rgba(0, 0, 0, .85);
    background-color: #ecf0f1;
    border: 1px solid rgba(0, 0, 0, .2)
}

.dark-mode .input-group-text {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(0, 0, 0, .3)
}

.input-group-lg .input-group-text,
.input-group-sm .input-group-text {
    padding-left: .8rem;
    padding-right: .8rem;
    font-size: 1.2rem
}

.input-group-lg .input-group-text {
    font-size: 1.8rem
}

.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-prepend>.btn {
    height: 2.6rem;
    line-height: 2.6rem;
    padding: 0 1rem;
    font-size: 1.2rem
}

.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-prepend>.btn {
    height: 4rem;
    line-height: 4rem;
    padding: 0 2rem;
    font-size: 1.8rem
}

.input-group-append>.btn,
.input-group-append>.input-group-text,
.input-group-prepend>.btn,
.input-group-prepend>.input-group-text,
.input-group>.form-control {
    border-radius: 0
}

.input-group-prepend:first-child>.btn,
.input-group-prepend:first-child>.input-group-text,
.input-group>.form-control:first-child {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem
}

.input-group-append:last-child>.btn,
.input-group-append:last-child>.input-group-text,
.input-group>.form-control:last-child {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem
}

.input-group-append:nth-child(even):not(:last-child)>.btn,
.input-group-append:nth-child(even):not(:last-child)>.input-group-text,
.input-group-append:nth-child(odd):not(:last-child)>.btn,
.input-group-append:nth-child(odd):not(:last-child)>.input-group-text,
.input-group-prepend:nth-child(even):not(:last-child)>.btn,
.input-group-prepend:nth-child(even):not(:last-child)>.input-group-text,
.input-group-prepend:nth-child(odd):not(:last-child)>.btn,
.input-group-prepend:nth-child(odd):not(:last-child)>.input-group-text,
.input-group>.form-control:nth-child(even):not(:last-child),
.input-group>.form-control:nth-child(odd):not(:last-child) {
    border-right: none
}

.input-group-append>.btn,
.input-group-append>.btn:hover,
.input-group-prepend>.btn,
.input-group-prepend>.btn:hover {
    border-color: rgba(0, 0, 0, .2)
}

.input-group-append>.btn:focus,
.input-group-prepend>.btn:focus {
    border-color: rgba(0, 0, 0, .2)
}

.input-group-append>.btn.active,
.input-group-prepend>.btn.active {
    border-color: rgba(0, 0, 0, .2)
}

.dark-mode .input-group-append>.btn,
.dark-mode .input-group-append>.btn:hover,
.dark-mode .input-group-prepend>.btn,
.dark-mode .input-group-prepend>.btn:hover {
    border-color: rgba(0, 0, 0, .3)
}

.dark-mode .input-group-append>.btn:focus,
.dark-mode .input-group-prepend>.btn:focus {
    border-color: rgba(0, 0, 0, .3)
}

.dark-mode .input-group-append>.btn.active,
.dark-mode .input-group-prepend>.btn.active {
    border-color: rgba(0, 0, 0, .3)
}

.input-group-append>.btn.btn-link,
.input-group-append>.btn.btn-link:hover,
.input-group-prepend>.btn.btn-link,
.input-group-prepend>.btn.btn-link:hover {
    border-color: rgba(0, 0, 0, .2)
}

.input-group-append>.btn.btn-link:focus,
.input-group-prepend>.btn.btn-link:focus {
    border-color: rgba(0, 0, 0, .2)
}

.dark-mode .input-group-append>.btn.btn-link,
.dark-mode .input-group-append>.btn.btn-link:hover,
.dark-mode .input-group-prepend>.btn.btn-link,
.dark-mode .input-group-prepend>.btn.btn-link:hover,
.input-group-append>.btn.btn-link.active,
.input-group-prepend>.btn.btn-link.active {
    border-color: rgba(0, 0, 0, .2)
}

.dark-mode .input-group-append>.btn.btn-link:focus,
.dark-mode .input-group-prepend>.btn.btn-link:focus {
    border-color: rgba(0, 0, 0, .2)
}

.dark-mode .input-group-append>.btn.btn-link.active,
.dark-mode .input-group-prepend>.btn.btn-link.active {
    border-color: rgba(0, 0, 0, .2)
}

.input-group-append>.btn.btn-primary,
.input-group-prepend>.btn.btn-primary {
    border-color: #1890ff
}

.dark-mode .input-group-append>.btn.btn-primary:hover,
.dark-mode .input-group-prepend>.btn.btn-primary:hover,
.input-group-append>.btn.btn-primary:hover,
.input-group-prepend>.btn.btn-primary:hover {
    border-color: #45a3fb
}

.dark-mode .input-group-append>.btn.btn-primary:focus,
.dark-mode .input-group-prepend>.btn.btn-primary:focus,
.input-group-append>.btn.btn-primary:focus,
.input-group-prepend>.btn.btn-primary:focus {
    border-color: #1890ff
}

.dark-mode .input-group-append>.btn.btn-primary,
.dark-mode .input-group-append>.btn.btn-primary.active,
.dark-mode .input-group-prepend>.btn.btn-primary,
.dark-mode .input-group-prepend>.btn.btn-primary.active,
.input-group-append>.btn.btn-primary.active,
.input-group-prepend>.btn.btn-primary.active {
    border-color: #1890ff
}

.input-group-append>.btn.btn-success,
.input-group-prepend>.btn.btn-success {
    border-color: #0be881
}

.dark-mode .input-group-append>.btn.btn-success:hover,
.dark-mode .input-group-prepend>.btn.btn-success:hover,
.input-group-append>.btn.btn-success:hover,
.input-group-prepend>.btn.btn-success:hover {
    border-color: #81eea8
}

.dark-mode .input-group-append>.btn.btn-success:focus,
.dark-mode .input-group-prepend>.btn.btn-success:focus,
.input-group-append>.btn.btn-success:focus,
.input-group-prepend>.btn.btn-success:focus {
    border-color: #0be881
}

.dark-mode .input-group-append>.btn.btn-success,
.dark-mode .input-group-append>.btn.btn-success.active,
.dark-mode .input-group-prepend>.btn.btn-success,
.dark-mode .input-group-prepend>.btn.btn-success.active,
.input-group-append>.btn.btn-success.active,
.input-group-prepend>.btn.btn-success.active {
    border-color: #0be881
}

.input-group-append>.btn.btn-secondary,
.input-group-prepend>.btn.btn-secondary {
    border-color: #ffc312
}

.dark-mode .input-group-append>.btn.btn-secondary:hover,
.dark-mode .input-group-prepend>.btn.btn-secondary:hover,
.input-group-append>.btn.btn-secondary:hover,
.input-group-prepend>.btn.btn-secondary:hover {
    border-color: #fbd35b
}

.dark-mode .input-group-append>.btn.btn-secondary:focus,
.dark-mode .input-group-prepend>.btn.btn-secondary:focus,
.input-group-append>.btn.btn-secondary:focus,
.input-group-prepend>.btn.btn-secondary:focus {
    border-color: #ffc312
}

.dark-mode .input-group-append>.btn.btn-secondary,
.dark-mode .input-group-append>.btn.btn-secondary.active,
.dark-mode .input-group-prepend>.btn.btn-secondary,
.dark-mode .input-group-prepend>.btn.btn-secondary.active,
.input-group-append>.btn.btn-secondary.active,
.input-group-prepend>.btn.btn-secondary.active {
    border-color: #ffc312
}

.input-group-append>.btn.btn-danger,
.input-group-prepend>.btn.btn-danger {
    border-color: #ff4d4f
}

.dark-mode .input-group-append>.btn.btn-danger:hover,
.dark-mode .input-group-prepend>.btn.btn-danger:hover,
.input-group-append>.btn.btn-danger:hover,
.input-group-prepend>.btn.btn-danger:hover {
    border-color: #ed586c
}

.dark-mode .input-group-append>.btn.btn-danger:focus,
.dark-mode .input-group-prepend>.btn.btn-danger:focus,
.input-group-append>.btn.btn-danger:focus,
.input-group-prepend>.btn.btn-danger:focus {
    border-color: #ff4d4f
}

.dark-mode .input-group-append>.btn.btn-danger,
.dark-mode .input-group-append>.btn.btn-danger.active,
.dark-mode .input-group-prepend>.btn.btn-danger,
.dark-mode .input-group-prepend>.btn.btn-danger.active,
.input-group-append>.btn.btn-danger.active,
.input-group-prepend>.btn.btn-danger.active {
    border-color: #ff4d4f
}

.dropdown {
    display: inline-block;
    position: relative
}

.dropdown-menu {
    position: absolute;
    visibility: hidden;
    z-index: 10;
    text-align: left;
    width: auto;
    min-width: 16.8rem;
    padding: .4rem;
    color: rgba(0, 0, 0, .85);
    background-color: #fff;
    border: 1px solid #bababc;
    border-radius: .4rem;
    -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .15)
}

.dark-mode .dropdown-menu {
    color: rgba(255, 255, 255, .8);
    background-color: #191c20;
    border-color: #111417;
    -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, .4);
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, .4);
    box-shadow: 0 2px 8px rgba(0, 0, 0, .4)
}

.dropdown.with-arrow.show .dropdown-menu::after,
.dropdown.with-arrow.show .dropdown-menu::before,
.dropdown.with-arrow.toggle-on-hover:hover .dropdown-menu::after,
.dropdown.with-arrow.toggle-on-hover:hover .dropdown-menu::before {
    content: " ";
    width: 0;
    height: 0;
    display: inline-block;
    position: absolute;
    z-index: 10
}

.dropdown-content {
    display: block;
    padding: 1.2rem
}

.dropdown-content>ol,
.dropdown-content>ul,
.sidebar-content>ol,
.sidebar-content>ul {
    margin-left: 0
}

.dropdown-divider {
    height: 1px;
    background-color: rgba(0, 0, 0, .05)
}

.dark-mode .dropdown-divider {
    background-color: rgba(255, 255, 255, .05)
}

.dropdown-item {
    display: block;
    white-space: nowrap;
    padding: .6rem 1.2rem;
    border-radius: .2rem;
    color: rgba(0, 0, 0, .85);
    background-color: #fff
}

.dropdown-item:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, .85);
    background-color: rgba(0, 0, 0, .025)
}

.dark-mode .dropdown-item {
    color: rgba(255, 255, 255, .8);
    background-color: #191c20
}

.dark-mode .dropdown-item:hover {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .025)
}

.dropdown-header {
    font-size: 1.4rem;
    font-weight: 500;
    padding: .6rem 1.2rem;
    margin: 0;
    color: rgba(0, 0, 0, .6)
}

.dark-mode .dropdown-header {
    color: rgba(255, 255, 255, .6)
}

.dropdown:not(.dropup):not(.dropright):not(.dropleft) .dropdown-menu {
    bottom: 50%;
    transform: translateY(50%)
}

.dropdown.dropup .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-right),
.dropdown:not(.dropup):not(.dropright):not(.dropleft) .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-right) {
    left: 0
}

.dropdown:not(.dropup):not(.dropright):not(.dropleft) .dropdown-menu.dropdown-menu-center {
    left: 50%;
    transform: translate(-50%, 50%)
}

.dropdown:not(.dropup):not(.dropright):not(.dropleft) .dropdown-menu.dropdown-menu-right {
    right: 0
}

.dropdown:not(.dropup):not(.dropright):not(.dropleft).show .dropdown-menu,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).toggle-on-hover:hover .dropdown-menu {
    bottom: 0;
    transform: translateY(100%);
    visibility: visible;
    -webkit-transition: .1s;
    transition: .1s
}

.dropdown:not(.dropup):not(.dropright):not(.dropleft).show .dropdown-menu.dropdown-menu-center,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).toggle-on-hover:hover .dropdown-menu.dropdown-menu-center {
    transform: translate(-50%, 100%)
}

.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.show .dropdown-menu,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.toggle-on-hover:hover .dropdown-menu {
    bottom: calc(.8rem*-1)
}

.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.show .dropdown-menu::after,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.toggle-on-hover:hover .dropdown-menu::after {
    border-left: .8rem solid transparent;
    border-right: .8rem solid transparent;
    border-bottom: .8rem solid #bababc
}

.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.show .dropdown-menu::before,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.toggle-on-hover:hover .dropdown-menu::before {
    border-left: .8rem solid transparent;
    border-right: .8rem solid transparent;
    border-bottom: .8rem solid #bababc;
    top: calc(.8rem*-1)
}

.dark-mode .dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.show .dropdown-menu::before,
.dark-mode .dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.toggle-on-hover:hover .dropdown-menu::before {
    border-bottom-color: #111417
}

.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.show .dropdown-menu::after,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.toggle-on-hover:hover .dropdown-menu::after {
    border-bottom-color: #fff;
    top: calc(.2rem + (.8rem*-1))
}

.dark-mode .dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.show .dropdown-menu::after,
.dark-mode .dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.toggle-on-hover:hover .dropdown-menu::after {
    border-bottom-color: #191c20
}

.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.show .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-right)::after,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.show .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-right)::before,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.toggle-on-hover:hover .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-right)::after,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.toggle-on-hover:hover .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-right)::before {
    left: .8rem
}

.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.show .dropdown-menu.dropdown-menu-center::after,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.show .dropdown-menu.dropdown-menu-center::before,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-center::after,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-center::before {
    left: calc(50% - .8rem)
}

.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.show .dropdown-menu.dropdown-menu-right::after,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.show .dropdown-menu.dropdown-menu-right::before,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-right::after,
.dropdown:not(.dropup):not(.dropright):not(.dropleft).with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-right::before {
    right: .8rem
}

.dropdown.dropup .dropdown-menu {
    top: 50%;
    transform: translateY(-50%)
}

.dropdown.dropup .dropdown-menu.dropdown-menu-center {
    left: 50%;
    transform: translate(-50%, -50%)
}

.dropdown.dropup .dropdown-menu.dropdown-menu-right {
    right: 0
}

.dropdown.dropup.show .dropdown-menu,
.dropdown.dropup.toggle-on-hover:hover .dropdown-menu {
    top: 0;
    transform: translateY(-100%);
    visibility: visible;
    -webkit-transition: .1s;
    transition: .1s
}

.dropdown.dropup.show .dropdown-menu.dropdown-menu-center,
.dropdown.dropup.toggle-on-hover:hover .dropdown-menu.dropdown-menu-center {
    transform: translate(-50%, -100%)
}

.dropdown.dropup.with-arrow.show .dropdown-menu,
.dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu {
    top: calc(.8rem*-1)
}

.dropdown.dropup.with-arrow.show .dropdown-menu::after {
    border-left: .8rem solid transparent;
    border-right: .8rem solid transparent;
    border-top: .8rem solid #bababc
}

.dropdown.dropup.with-arrow.show .dropdown-menu::before {
    bottom: calc(.8rem*-1)
}

.dropdown.dropup.with-arrow.show .dropdown-menu::before,
.dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu::after,
.dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu::before {
    border-left: .8rem solid transparent;
    border-right: .8rem solid transparent;
    border-top: .8rem solid #bababc
}

.dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu::before {
    bottom: calc(.8rem*-1)
}

.dark-mode .dropdown.dropup.with-arrow.show .dropdown-menu::before,
.dark-mode .dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu::before {
    border-top-color: #111417
}

.dropdown.dropup.with-arrow.show .dropdown-menu::after,
.dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu::after {
    border-top-color: #fff;
    bottom: calc(.2rem + (.8rem*-1))
}

.dark-mode .dropdown.dropup.with-arrow.show .dropdown-menu::after,
.dark-mode .dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu::after {
    border-top-color: #191c20
}

.dropdown.dropup.with-arrow.show .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-right)::after,
.dropdown.dropup.with-arrow.show .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-right)::before,
.dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-right)::after,
.dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-right)::before {
    left: .8rem
}

.dropdown.dropup.with-arrow.show .dropdown-menu.dropdown-menu-center::after,
.dropdown.dropup.with-arrow.show .dropdown-menu.dropdown-menu-center::before,
.dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-center::after,
.dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-center::before {
    left: calc(50% - .8rem)
}

.dropdown.dropup.with-arrow.show .dropdown-menu.dropdown-menu-right::after,
.dropdown.dropup.with-arrow.show .dropdown-menu.dropdown-menu-right::before,
.dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-right::after,
.dropdown.dropup.with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-right::before {
    right: .8rem
}

.dropdown.dropright .dropdown-menu {
    right: 50%;
    transform: translateX(50%)
}

.dropdown.dropright .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-up) {
    top: 0
}

.dropdown.dropright .dropdown-menu.dropdown-menu-center {
    top: 50%;
    transform: translate(50%, -50%)
}

.dropdown.dropright .dropdown-menu.dropdown-menu-up {
    bottom: 0
}

.dropdown.dropright.show .dropdown-menu,
.dropdown.dropright.toggle-on-hover:hover .dropdown-menu {
    right: 0;
    transform: translateX(100%);
    visibility: visible;
    -webkit-transition: .1s;
    transition: .1s
}

.dropdown.dropright.show .dropdown-menu.dropdown-menu-center,
.dropdown.dropright.toggle-on-hover:hover .dropdown-menu.dropdown-menu-center {
    transform: translate(100%, -50%)
}

.dropdown.dropright.with-arrow.show .dropdown-menu,
.dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu {
    right: calc(.8rem*-1)
}

.dropdown.dropright.with-arrow.show .dropdown-menu::after {
    border-top: .8rem solid transparent;
    border-bottom: .8rem solid transparent;
    border-right: .8rem solid #bababc
}

.dropdown.dropright.with-arrow.show .dropdown-menu::before {
    left: calc(.8rem*-1)
}

.dropdown.dropright.with-arrow.show .dropdown-menu::before,
.dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu::after,
.dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu::before {
    border-top: .8rem solid transparent;
    border-bottom: .8rem solid transparent;
    border-right: .8rem solid #bababc
}

.dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu::before {
    left: calc(.8rem*-1)
}

.dark-mode .dropdown.dropright.with-arrow.show .dropdown-menu::before,
.dark-mode .dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu::before {
    border-right-color: #111417
}

.dropdown.dropright.with-arrow.show .dropdown-menu::after,
.dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu::after {
    border-right-color: #fff;
    left: calc(.2rem + (.8rem*-1))
}

.dark-mode .dropdown.dropright.with-arrow.show .dropdown-menu::after,
.dark-mode .dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu::after {
    border-right-color: #191c20
}

.dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-up)::after,
.dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-up)::before,
.dropdown.dropright.with-arrow.show .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-up)::after,
.dropdown.dropright.with-arrow.show .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-up)::before,
.dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-up)::after,
.dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-up)::before {
    top: .8rem
}

.dropdown.dropright.with-arrow.show .dropdown-menu.dropdown-menu-center::after,
.dropdown.dropright.with-arrow.show .dropdown-menu.dropdown-menu-center::before,
.dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-center::after,
.dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-center::before {
    top: calc(50% - .8rem)
}

.dropdown.dropright.with-arrow.show .dropdown-menu.dropdown-menu-up::after,
.dropdown.dropright.with-arrow.show .dropdown-menu.dropdown-menu-up::before,
.dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-up::after,
.dropdown.dropright.with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-up::before {
    bottom: .8rem
}

.dropdown.dropleft .dropdown-menu {
    left: 50%;
    transform: translateX(-50%)
}

.dropdown.dropleft .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-up) {
    top: 0
}

.dropdown.dropleft .dropdown-menu.dropdown-menu-center {
    top: 50%;
    transform: translate(-50%, -50%)
}

.dropdown.dropleft .dropdown-menu.dropdown-menu-up {
    bottom: 0
}

.dropdown.dropleft.show .dropdown-menu,
.dropdown.dropleft.toggle-on-hover:hover .dropdown-menu {
    left: 0;
    transform: translateX(-100%);
    visibility: visible;
    -webkit-transition: .1s;
    transition: .1s
}

.dropdown.dropleft.show .dropdown-menu.dropdown-menu-center,
.dropdown.dropleft.toggle-on-hover:hover .dropdown-menu.dropdown-menu-center {
    transform: translate(-100%, -50%)
}

.dropdown.dropleft.with-arrow.show .dropdown-menu,
.dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu {
    left: calc(.8rem*-1)
}

.dropdown.dropleft.with-arrow.show .dropdown-menu::after {
    border-top: .8rem solid transparent;
    border-bottom: .8rem solid transparent;
    border-left: .8rem solid #bababc
}

.dropdown.dropleft.with-arrow.show .dropdown-menu::before {
    right: calc(.8rem*-1)
}

.dropdown.dropleft.with-arrow.show .dropdown-menu::before,
.dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu::after,
.dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu::before {
    border-top: .8rem solid transparent;
    border-bottom: .8rem solid transparent;
    border-left: .8rem solid #bababc
}

.dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu::before {
    right: calc(.8rem*-1)
}

.dark-mode .dropdown.dropleft.with-arrow.show .dropdown-menu::before,
.dark-mode .dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu::before {
    border-left-color: #111417
}

.dropdown.dropleft.with-arrow.show .dropdown-menu::after,
.dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu::after {
    border-left-color: #fff;
    right: calc(.2rem + (.8rem*-1))
}

.dark-mode .dropdown.dropleft.with-arrow.show .dropdown-menu::after,
.dark-mode .dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu::after {
    border-left-color: #191c20
}

.dropdown.dropleft.with-arrow.show .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-up)::after,
.dropdown.dropleft.with-arrow.show .dropdown-menu:not(.dropdown-menu-center):not(.dropdown-menu-up)::before {
    top: .8rem
}

.dropdown.dropleft.with-arrow.show .dropdown-menu.dropdown-menu-center::after,
.dropdown.dropleft.with-arrow.show .dropdown-menu.dropdown-menu-center::before,
.dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-center::after,
.dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-center::before {
    top: calc(50% - .8rem)
}

.dropdown.dropleft.with-arrow.show .dropdown-menu.dropdown-menu-up::after,
.dropdown.dropleft.with-arrow.show .dropdown-menu.dropdown-menu-up::before,
.dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-up::after,
.dropdown.dropleft.with-arrow.toggle-on-hover:hover .dropdown-menu.dropdown-menu-up::before {
    bottom: .8rem
}

.modal,
.modal-dialog {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto
}

.modal {
    background-color: rgba(0, 0, 0, .8);
    -webkit-transition: all .4s cubic-bezier(.25, .8, .25, 1);
    transition: all .4s cubic-bezier(.25, .8, .25, 1);
    z-index: 99;
    outline: 0
}

.dark-mode .modal {
    background-color: rgba(0, 0, 0, .95)
}

.modal-dialog {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column
}

@media screen and (-ms-high-contrast:active),
(-ms-high-contrast:none) {
    .modal.ie-scroll-fix .modal-dialog {
        display: block
    }
}

.dark-mode .modal-content,
.modal-content {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.modal-content {
    margin: auto;
    width: 40rem;
    max-width: 100%;
    padding: 3rem;
    color: rgba(0, 0, 0, .85);
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .4rem
}

.dark-mode .modal-content {
    color: rgba(255, 255, 255, .8);
    background-color: #191c20;
    border-color: rgba(0, 0, 0, .2)
}

@media (max-width:576px) {
    .modal-content {
        padding: 2rem
    }
}

.modal-title {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 0
}

.dark-mode .modal-content-media,
.modal-content-media {
    padding: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.modal-full .modal-content {
    width: 100%;
    border: 0;
    border-radius: 0
}

.modal-content .close {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    top: -100%;
    left: 1rem;
    z-index: 10;
    padding: 0;
    -webkit-transition: all .4s cubic-bezier(.25, .8, .25, 1);
    transition: all .4s cubic-bezier(.25, .8, .25, 1);
    font-size: 2rem;
    width: 4rem;
    height: 4rem;
    color: #fff;
    background-color: rgba(0, 0, 0, .7);
    border-radius: 50%;
    border-color: transparent
}

.modal-content .close:hover {
    text-decoration: none;
    color: #fff;
    background-color: rgba(0, 0, 0, .6)
}

.dark-mode .modal-content .close {
    color: #fff;
    background-color: rgba(255, 255, 255, .1)
}

.dark-mode .modal-content .close:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, .2)
}

.modal-content .close:focus {
    -webkit-transition: none;
    transition: none;
    outline: 2px solid rgba(24, 144, 255, .3);
    outline-offset: 2px
}

.dark-mode .modal-content .close:focus {
    outline-color: rgba(24, 144, 255, .3)
}

.modal.show,
.modal.show .modal-dialog,
.modal:target,
.modal:target .modal-dialog {
    top: 0
}

.modal.show~.page-wrapper .content-wrapper,
.modal.show~.page-wrapper .sidebar,
.modal:target~.page-wrapper .content-wrapper,
.modal:target~.page-wrapper .sidebar {
    overflow: hidden
}

.modal.show .modal-content .close,
.modal:target .modal-content .close {
    top: 1rem
}

.alert {
    position: relative;
    padding: 1rem 2rem;
    color: rgba(0, 0, 0, .85);
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .4rem
}

.dark-mode .alert {
    color: rgba(255, 255, 255, .8);
    background-color: #191c20;
    border-color: rgba(255, 255, 255, .1)
}

.alert-heading,
.dark-mode .alert-heading {
    color: inherit;
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0 0 .5rem
}

.alert-link,
.dark-mode .alert-link {
    cursor: pointer;
    color: inherit;
    text-decoration: underline
}

.alert-link:hover,
.dark-mode .alert-link:hover {
    color: inherit;
    opacity: .75
}

.alert .close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: inherit;
    padding: .4rem .6rem;
    line-height: 1;
    font-size: 1.6rem;
    background-color: transparent;
    border-color: transparent
}

.alert .close:focus {
    outline: 2px solid rgba(0, 0, 0, .5);
    outline-offset: 2px
}

.dark-mode .alert .close:focus {
    outline-color: rgba(255, 255, 255, .5)
}

.alert.dispose,
.sticky-alerts .alert.alert-block.dispose {
    display: none
}

.sticky-alerts .alert {
    display: none;
    width: 27rem;
    margin-top: 1rem;
    right: -50rem;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045)
}

.sticky-alerts .alert.show {
    right: 0;
    -webkit-transition: all .4s cubic-bezier(.25, .8, .25, 1);
    transition: all .4s cubic-bezier(.25, .8, .25, 1)
}

.sticky-alerts .alert.fade {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s .4s, opacity .4s linear;
    transition: visibility 0s .4s, opacity .4s linear
}

.sticky-alerts .alert.alert-block {
    display: block
}

.alert.alert-primary.filled,
.alert.alert-primary.filled-lm {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff
}

.alert.alert-success.filled,
.alert.alert-success.filled-lm {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881
}

.alert.alert-secondary.filled,
.alert.alert-secondary.filled-lm {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312
}

.alert.alert-danger.filled,
.alert.alert-danger.filled-lm {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f
}

.dark-mode .alert.alert-primary.filled,
.dark-mode .alert.alert-primary.filled-dm {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.dark-mode .alert.alert-success.filled,
.dark-mode .alert.alert-success.filled-dm {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.dark-mode .alert.alert-secondary.filled,
.dark-mode .alert.alert-secondary.filled-dm {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.dark-mode .alert.alert-danger.filled,
.dark-mode .alert.alert-danger.filled-dm {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.alert.alert-primary,
.alert.alert-success {
    color: rgba(0, 0, 0, .85);
    background-color: #cfe7fd;
    border-color: #1890ff
}

.alert.alert-success {
    background-color: #d5f9e5;
    border-color: #0be881
}

.alert.alert-danger,
.alert.alert-secondary {
    color: rgba(0, 0, 0, .85);
    background-color: #fef1ce;
    border-color: #ffc312
}

.alert.alert-danger {
    background-color: #f8d0d9;
    border-color: #ff4d4f
}

.dark-mode .alert.alert-primary {
    color: #1890ff;
    background-color: #172636;
    border-color: #0d518f
}

.dark-mode .alert.alert-success {
    color: #0be881;
    background-color: #17312a;
    border-color: #0d8951
}

.dark-mode .alert.alert-secondary {
    color: #ffc312;
    background-color: #302c1d;
    border-color: #8c7010
}

.dark-mode .alert.alert-danger {
    color: #ff4d4f;
    background-color: #301923;
    border-color: #8c0e2e
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
    width: 100%;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-left: calc(3rem - 1rem);
    padding-right: calc(3rem - 1rem)
}

@media (max-width:576px) {

    .navbar .container,
    .navbar .container-fluid,
    .navbar .container-lg,
    .navbar .container-md,
    .navbar .container-sm,
    .navbar .container-xl {
        padding-left: calc(2rem - 1rem);
        padding-right: calc(2rem - 1rem)
    }
}

.navbar-brand,
.navbar-content,
.navbar-nav,
.navbar-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-width: 0
}

.navbar-brand:not(:first-child),
.navbar-content:not(:first-child),
.navbar-nav:not(:first-child),
.navbar-text:not(:first-child),
.navbar>.form-inline:not(:first-child) {
    margin-left: 1.5rem
}

.navbar-text {
    color: rgba(0, 0, 0, .7)
}

.dark-mode .navbar-text {
    color: rgba(255, 255, 255, .65)
}

.navbar-nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    height: 5rem
}

.nav-item {
    height: 100%;
    margin-bottom: 0
}

.nav-link {
    cursor: pointer;
    display: inline-block;
    display: flex;
    align-items: center;
    height: 100%;
    white-space: nowrap;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: rgba(0, 0, 0, .85);
    background-color: transparent
}

.dark-mode .nav-link {
    color: rgba(255, 255, 255, .8);
    background-color: transparent
}

.nav-link:hover {
    text-decoration: none
}

.dark-mode .nav-link:hover,
.nav-item.active>.nav-link,
.nav-item.show>.nav-link,
.nav-link:hover {
    color: #1890ff;
    background-color: transparent
}

.dark-mode .nav-item.active>.nav-link,
.dark-mode .nav-item.show>.nav-link {
    color: #1890ff;
    background-color: transparent;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.dark-mode .nav-item.active>.nav-link:hover,
.dark-mode .nav-item.show>.nav-link:hover,
.nav-item.active>.nav-link:hover,
.nav-item.show>.nav-link:hover {
    color: #45a3fb;
    background-color: transparent
}

.navbar-nav:first-child>.nav-item:first-child>.nav-link {
    padding-left: 0
}

.navbar-nav:last-child>.nav-item:last-child>.nav-link {
    padding-right: 0
}

.navbar-brand {
    font-size: 2rem;
    font-weight: 500;
    color: rgba(0, 0, 0, .85)
}

.dark-mode .navbar-brand {
    color: rgba(255, 255, 255, .8)
}

.navbar-brand:hover {
    color: rgba(0, 0, 0, .7);
    text-decoration: none
}

.dark-mode .navbar-brand:hover {
    color: rgba(255, 255, 255, .65)
}

.navbar-brand img,
.sidebar-brand img {
    height: 2.2rem;
    margin-right: 1rem
}

.navbar .btn-action {
    width: 3.6rem;
    padding-left: 0;
    padding-right: 0
}

.navbar>.form-inline>.form-control,
.navbar>.form-inline>.form-group .form-control {
    min-width: 12rem
}

.navbar>.form-inline>.input-group {
    min-width: 20rem
}

@media (max-width:576px) {

    .navbar>.form-inline>.form-control,
    .navbar>.form-inline>.form-group .form-control {
        min-width: 10rem
    }

    .navbar>.form-inline>.input-group {
        min-width: 18rem
    }
}

.sidebar-menu {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
}

.sidebar-content {
    display: block;
    padding: 0;
    margin: 2.5rem
}

.sidebar-divider {
    padding: 0;
    margin: .5rem 2.5rem;
    height: 1px;
    background-color: rgba(0, 0, 0, .05)
}

.dark-mode .sidebar-divider {
    background-color: rgba(255, 255, 255, .05)
}

.sidebar-link {
    display: block;
    padding: .5rem 2.5rem;
    margin: 0;
    min-height: 3rem;
    height: auto;
    color: rgba(0, 0, 0, .7);
    background-color: transparent;
    border: 0 solid transparent;
    border-radius: 0
}

.sidebar-link:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, .85);
    background-color: transparent;
    border-color: transparent
}

.dark-mode .sidebar-link {
    color: rgba(255, 255, 255, .65)
}

.dark-mode .sidebar-link,
.dark-mode .sidebar-link:hover,
.sidebar-link.active {
    background-color: transparent;
    border-color: transparent
}

.dark-mode .sidebar-link:hover {
    color: rgba(255, 255, 255, .8)
}

.sidebar-link.active {
    color: #1890ff
}

.dark-mode .sidebar-link.active,
.dark-mode .sidebar-link.active:hover,
.sidebar-link.active:hover {
    color: #45a3fb;
    background-color: transparent;
    border-color: transparent
}

.dark-mode .sidebar-link.active {
    color: #1890ff;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.sidebar-title {
    padding: 0;
    margin: .5rem 2.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: rgba(0, 0, 0, .85)
}

.dark-mode .sidebar-title {
    color: rgba(255, 255, 255, .8)
}

.sidebar-brand,
.sidebar-icon,
.sidebar-link-with-icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.sidebar-icon {
    -ms-flex-pack: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    font-size: 1.6rem;
    margin-right: 1rem;
    color: rgba(0, 0, 0, .7);
    background-color: rgba(0, 0, 0, .05);
    border: 0 solid transparent;
    border-radius: .4rem
}

.sidebar-link-with-icon:hover .sidebar-icon {
    color: rgba(0, 0, 0, .85);
    background-color: rgba(0, 0, 0, .05);
    border-color: transparent
}

.dark-mode .sidebar-icon {
    color: rgba(255, 255, 255, .65);
    background-color: rgba(255, 255, 255, .05);
    border-color: transparent
}

.dark-mode .sidebar-link-with-icon.active:hover .sidebar-icon,
.dark-mode .sidebar-link-with-icon:hover .sidebar-icon {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: transparent
}

.sidebar-link-with-icon.active .sidebar-icon,
.sidebar-link-with-icon.active:hover .sidebar-icon {
    color: rgba(0, 0, 0, .7);
    background-color: rgba(0, 0, 0, .05);
    border-color: transparent
}

.sidebar-link-with-icon.active:hover .sidebar-icon {
    color: rgba(0, 0, 0, .85)
}

.dark-mode .sidebar-link-with-icon.active .sidebar-icon {
    color: rgba(255, 255, 255, .65);
    background-color: rgba(255, 255, 255, .05);
    border-color: transparent
}

.sidebar-brand {
    padding: 0;
    margin: .5rem 2.5rem;
    font-size: 2rem;
    font-weight: 500;
    color: rgba(0, 0, 0, .85)
}

.dark-mode .sidebar-brand {
    color: rgba(255, 255, 255, .8)
}

.sidebar-brand:hover {
    color: rgba(0, 0, 0, .7);
    text-decoration: none
}

.dark-mode .sidebar-brand:hover {
    color: rgba(255, 255, 255, .65)
}

.page-item {
    display: inline-block;
    list-style: none;
    vertical-align: middle;
    margin-bottom: 0
}

.page-item.ellipsis:before {
    content: "\2026"
}

.page-item.ellipsis,
.page-link {
    display: inline-block;
    padding: 0 .5rem;
    margin: 0 .2rem .5rem 0;
    font-size: 1.4rem;
    min-width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
    text-align: center;
    color: rgba(0, 0, 0, .7);
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .4rem
}

.dark-mode .page-item.ellipsis,
.dark-mode .page-link {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .05);
    border-color: transparent
}

.page-link {
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .05)
}

.dark-mode .page-link {
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .1)
}

.page-link:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, .7);
    background-color: #f7f7f7;
    border-color: rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .05)
}

.dark-mode .page-item.active .page-link,
.dark-mode .page-link:hover {
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .1)
}

.dark-mode .page-link:hover {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .1);
    border-color: transparent
}

.page-item.active .page-link {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    pointer-events: none
}

.dark-mode .page-item.active .page-link {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.pagination-rounded .page-item.ellipsis,
.pagination-rounded .page-link {
    border-radius: 50%
}

.pagination-lg .page-item.ellipsis,
.pagination-lg .page-link {
    padding: 0 .5rem;
    margin: 0 .2rem .5rem 0;
    font-size: 1.8rem;
    min-width: 4.2rem;
    height: 4.2rem;
    line-height: 4.2rem
}

.pagination-sm .page-item.ellipsis,
.pagination-sm .page-link {
    padding: 0 .5rem;
    margin: 0 .2rem .5rem 0;
    font-size: 1.2rem;
    min-width: 2.8rem;
    height: 2.8rem;
    line-height: 2.8rem
}

.dark-mode .page-item.disabled .page-link,
.page-item.disabled .page-link {
    opacity: .6;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    pointer-events: none
}

.breadcrumb {
    padding: 0;
    background-color: transparent;
    border: 0 solid transparent;
    border-radius: 0
}

.dark-mode .breadcrumb {
    background-color: transparent;
    border-color: transparent
}

.breadcrumb-item {
    display: inline-block;
    list-style: none;
    margin-bottom: 0
}

.breadcrumb-item:before {
    content: "\002F";
    margin-left: 1rem;
    margin-right: 1.5rem
}

.breadcrumb-item:first-child:before {
    display: none
}

.breadcrumb-item.active a {
    pointer-events: none;
    color: inherit
}

.dark-mode .breadcrumb-item a {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.dark-mode .breadcrumb-item.active a {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

[data-toggle=tooltip] {
    position: relative
}

[data-toggle=tooltip]::after,
[data-toggle=tooltip]::before {
    background-color: #191c20;
    position: absolute;
    visibility: hidden;
    z-index: 90
}

[data-toggle=tooltip]::before {
    content: " ";
    width: 1rem;
    height: 1rem;
    opacity: 0
}

.dark-mode [data-toggle=tooltip]::before {
    background-color: #fff
}

[data-toggle=tooltip]::after {
    content: attr(data-title);
    white-space: normal;
    width: 10rem;
    font-size: 1.2rem;
    line-height: 1.5;
    padding: .5rem 1rem;
    color: rgba(255, 255, 255, .8);
    border-radius: .4rem;
    text-align: center
}

.dark-mode [data-toggle=tooltip]::after {
    color: rgba(0, 0, 0, .85);
    background-color: #fff
}

[data-toggle=tooltip]:not([data-placement])::before,
[data-toggle=tooltip][data-placement=top]::before {
    left: 50%;
    top: -.5rem;
    transform: translate(-50%, -100%) rotate(45deg)
}

[data-toggle=tooltip]:not([data-placement])::after,
[data-toggle=tooltip][data-placement=top]::after {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

[data-toggle=tooltip][data-placement=bottom]::before {
    left: 50%;
    bottom: -.5rem;
    transform: translate(-50%, 100%) rotate(45deg)
}

[data-toggle=tooltip][data-placement=bottom]::after {
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%)
}

[data-toggle=tooltip][data-placement=left]::before {
    left: -.5rem;
    transform: translate(-100%, 100%) rotate(45deg)
}

[data-toggle=tooltip][data-placement=left]::after {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

[data-toggle=tooltip][data-placement=right]::before {
    right: -.5rem;
    transform: translate(100%, 100%) rotate(45deg)
}

[data-toggle=tooltip][data-placement=right]::after {
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%)
}

[data-toggle=tooltip]:not([data-target-breakpoint]):focus::before,
[data-toggle=tooltip]:not([data-target-breakpoint]):hover::before {
    visibility: visible;
    opacity: 1;
    transition: opacity .5s;
    -webkit-transition: opacity .5s
}

[data-toggle=tooltip]:not([data-target-breakpoint]):focus::after,
[data-toggle=tooltip]:not([data-target-breakpoint]):hover::after {
    visibility: visible;
    -webkit-transition: .1s;
    transition: .1s
}

[data-toggle=tooltip]:not([data-target-breakpoint]):not([data-placement]):focus::after,
[data-toggle=tooltip]:not([data-target-breakpoint]):not([data-placement]):hover::after,
[data-toggle=tooltip]:not([data-target-breakpoint])[data-placement=top]:focus::after,
[data-toggle=tooltip]:not([data-target-breakpoint])[data-placement=top]:hover::after {
    top: -1rem;
    transform: translate(-50%, -100%)
}

[data-toggle=tooltip]:not([data-target-breakpoint])[data-placement=bottom]:focus::after,
[data-toggle=tooltip]:not([data-target-breakpoint])[data-placement=bottom]:hover::after {
    bottom: -1rem;
    transform: translate(-50%, 100%)
}

[data-toggle=tooltip]:not([data-target-breakpoint])[data-placement=left]:focus::after,
[data-toggle=tooltip]:not([data-target-breakpoint])[data-placement=left]:hover::after {
    left: -1rem;
    transform: translate(-100%, -50%)
}

[data-toggle=tooltip]:not([data-target-breakpoint])[data-placement=right]:focus::after,
[data-toggle=tooltip]:not([data-target-breakpoint])[data-placement=right]:hover::after {
    right: -1rem;
    transform: translate(100%, -50%)
}

@media (min-width:769px) {

    [data-toggle=tooltip][data-target-breakpoint=md]:focus::before,
    [data-toggle=tooltip][data-target-breakpoint=md]:hover::before {
        visibility: visible;
        opacity: 1;
        transition: opacity .5s;
        -webkit-transition: opacity .5s
    }

    [data-toggle=tooltip][data-target-breakpoint=md]:focus::after,
    [data-toggle=tooltip][data-target-breakpoint=md]:hover::after {
        visibility: visible;
        -webkit-transition: .1s;
        transition: .1s
    }

    [data-toggle=tooltip][data-target-breakpoint=md]:not([data-placement]):focus::after,
    [data-toggle=tooltip][data-target-breakpoint=md]:not([data-placement]):hover::after,
    [data-toggle=tooltip][data-target-breakpoint=md][data-placement=top]:focus::after,
    [data-toggle=tooltip][data-target-breakpoint=md][data-placement=top]:hover::after {
        top: -1rem;
        transform: translate(-50%, -100%)
    }

    [data-toggle=tooltip][data-target-breakpoint=md][data-placement=bottom]:focus::after,
    [data-toggle=tooltip][data-target-breakpoint=md][data-placement=bottom]:hover::after {
        bottom: -1rem;
        transform: translate(-50%, 100%)
    }

    [data-toggle=tooltip][data-target-breakpoint=md][data-placement=left]:focus::after,
    [data-toggle=tooltip][data-target-breakpoint=md][data-placement=left]:hover::after {
        left: -1rem;
        transform: translate(-100%, -50%)
    }

    [data-toggle=tooltip][data-target-breakpoint=md][data-placement=right]:focus::after,
    [data-toggle=tooltip][data-target-breakpoint=md][data-placement=right]:hover::after {
        right: -1rem;
        transform: translate(100%, -50%)
    }
}

@media (min-width:993px) {

    [data-toggle=tooltip][data-target-breakpoint=lg]:focus::before,
    [data-toggle=tooltip][data-target-breakpoint=lg]:hover::before {
        visibility: visible;
        opacity: 1;
        transition: opacity .5s;
        -webkit-transition: opacity .5s
    }

    [data-toggle=tooltip][data-target-breakpoint=lg]:focus::after,
    [data-toggle=tooltip][data-target-breakpoint=lg]:hover::after {
        visibility: visible;
        -webkit-transition: .1s;
        transition: .1s
    }

    [data-toggle=tooltip][data-target-breakpoint=lg]:not([data-placement]):focus::after,
    [data-toggle=tooltip][data-target-breakpoint=lg]:not([data-placement]):hover::after,
    [data-toggle=tooltip][data-target-breakpoint=lg][data-placement=top]:focus::after,
    [data-toggle=tooltip][data-target-breakpoint=lg][data-placement=top]:hover::after {
        top: -1rem;
        transform: translate(-50%, -100%)
    }

    [data-toggle=tooltip][data-target-breakpoint=lg][data-placement=bottom]:focus::after,
    [data-toggle=tooltip][data-target-breakpoint=lg][data-placement=bottom]:hover::after {
        bottom: -1rem;
        transform: translate(-50%, 100%)
    }

    [data-toggle=tooltip][data-target-breakpoint=lg][data-placement=left]:focus::after,
    [data-toggle=tooltip][data-target-breakpoint=lg][data-placement=left]:hover::after {
        left: -1rem;
        transform: translate(-100%, -50%)
    }

    [data-toggle=tooltip][data-target-breakpoint=lg][data-placement=right]:focus::after,
    [data-toggle=tooltip][data-target-breakpoint=lg][data-placement=right]:hover::after {
        right: -1rem;
        transform: translate(100%, -50%)
    }
}

.badge {
    display: inline-block;
    position: relative;
    font-size: 1.2rem;
    line-height: 1.2;
    padding: .2rem .8rem;
    color: rgba(0, 0, 0, .85);
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .4rem
}

.dark-mode .badge {
    color: rgba(255, 255, 255, .8);
    background-color: transparent;
    border-color: rgba(255, 255, 255, .2)
}

.badge-pill {
    border-radius: 3rem
}

.badge.badge-primary,
.dark-mode .badge.badge-primary {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff
}

.badge.badge-success {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881
}

.badge.badge-secondary {
    color: rgba(0, 0, 0, .85);
    background-color: #ffc312;
    border-color: #ffc312
}

.badge.badge-danger {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f
}

.dark-mode .badge.badge-primary {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.dark-mode .badge.badge-secondary,
.dark-mode .badge.badge-success {
    color: rgba(0, 0, 0, .85);
    background-color: #0be881;
    border-color: #0be881;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.dark-mode .badge.badge-secondary {
    background-color: #ffc312;
    border-color: #ffc312
}

.dark-mode .badge.badge-danger {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

a.badge:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, .85);
    background-color: #f7f7f7;
    border-color: rgba(0, 0, 0, .2)
}

.dark-mode a.badge.badge-primary:hover,
a.badge.badge-primary:hover {
    color: #fff;
    background-color: #45a3fb;
    border-color: #45a3fb
}

.dark-mode a.badge.badge-success:hover,
a.badge.badge-success:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #81eea8;
    border-color: #81eea8
}

.dark-mode a.badge.badge-secondary:hover,
a.badge.badge-secondary:hover {
    color: rgba(0, 0, 0, .85);
    background-color: #fbd35b;
    border-color: #fbd35b
}

.dark-mode a.badge.badge-danger:hover,
a.badge.badge-danger:hover {
    color: #fff;
    background-color: #ed586c;
    border-color: #ed586c
}

.dark-mode a.badge:hover {
    color: rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .025);
    border-color: rgba(255, 255, 255, .2)
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.p-0 {
    padding: 0 !important
}

.p-5 {
    padding: .5rem !important
}

.p-10 {
    padding: 1rem !important
}

.p-15 {
    padding: 1.5rem !important
}

.p-20 {
    padding: 2rem !important
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.px-5 {
    padding-left: .5rem !important;
    padding-right: .5rem !important
}

.px-10 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
}

.px-15 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
}

.px-20 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-5 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-5 {
    padding-top: .5rem !important
}

.pt-10 {
    padding-top: 1rem !important
}

.pt-15 {
    padding-top: 1.5rem !important
}

.pt-20 {
    padding-top: 2rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-5 {
    padding-bottom: .5rem !important
}

.pb-10 {
    padding-bottom: 1rem !important
}

.pb-15 {
    padding-bottom: 1.5rem !important
}

.pb-20 {
    padding-bottom: 2rem !important
}

.pl-0 {
    padding-left: 0 !important
}

.pl-5 {
    padding-left: .5rem !important
}

.pl-10 {
    padding-left: 1rem !important
}

.pl-15 {
    padding-left: 1.5rem !important
}

.pl-20 {
    padding-left: 2rem !important
}

.pr-0 {
    padding-right: 0 !important
}

.pr-5 {
    padding-right: .5rem !important
}

.pr-10 {
    padding-right: 1rem !important
}

.pr-15 {
    padding-right: 1.5rem !important
}

.pr-20 {
    padding-right: 2rem !important
}

.m-0 {
    margin: 0 !important
}

.m-5 {
    margin: .5rem !important
}

.m-10 {
    margin: 1rem !important
}

.m-15 {
    margin: 1.5rem !important
}

.m-20 {
    margin: 2rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.mx-5 {
    margin-left: .5rem !important;
    margin-right: .5rem !important
}

.mx-10 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
}

.mx-15 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important
}

.mx-20 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-5 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-5 {
    margin-top: .5rem !important
}

.mt-10 {
    margin-top: 1rem !important
}

.mt-15 {
    margin-top: 1.5rem !important
}

.mt-20 {
    margin-top: 2rem !important
}

.mt-auto {
    margin-top: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-5 {
    margin-bottom: .5rem !important
}

.mb-10 {
    margin-bottom: 1rem !important
}

.mb-15 {
    margin-bottom: 1.5rem !important
}

.mb-20 {
    margin-bottom: 2rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ml-0 {
    margin-left: 0 !important
}

.ml-5 {
    margin-left: .5rem !important
}

.ml-10 {
    margin-left: 1rem !important
}

.ml-15 {
    margin-left: 1.5rem !important
}

.ml-20 {
    margin-left: 2rem !important
}

.ml-auto {
    margin-left: auto !important
}

.mr-0 {
    margin-right: 0 !important
}

.mr-5 {
    margin-right: .5rem !important
}

.mr-10 {
    margin-right: 1rem !important
}

.mr-15 {
    margin-right: 1.5rem !important
}

.mr-20 {
    margin-right: 2rem !important
}

.mr-auto {
    margin-right: auto !important
}

@media (min-width:577px) {
    .p-sm-0 {
        padding: 0 !important
    }

    .p-sm-5 {
        padding: .5rem !important
    }

    .p-sm-10 {
        padding: 1rem !important
    }

    .p-sm-15 {
        padding: 1.5rem !important
    }

    .p-sm-20 {
        padding: 2rem !important
    }

    .px-sm-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-sm-5 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-sm-10 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-sm-15 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-sm-20 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-sm-5 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-sm-10 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-sm-15 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-sm-20 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pt-sm-5 {
        padding-top: .5rem !important
    }

    .pt-sm-10 {
        padding-top: 1rem !important
    }

    .pt-sm-15 {
        padding-top: 1.5rem !important
    }

    .pt-sm-20 {
        padding-top: 2rem !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pb-sm-5 {
        padding-bottom: .5rem !important
    }

    .pb-sm-10 {
        padding-bottom: 1rem !important
    }

    .pb-sm-15 {
        padding-bottom: 1.5rem !important
    }

    .pb-sm-20 {
        padding-bottom: 2rem !important
    }

    .pl-sm-0 {
        padding-left: 0 !important
    }

    .pl-sm-5 {
        padding-left: .5rem !important
    }

    .pl-sm-10 {
        padding-left: 1rem !important
    }

    .pl-sm-15 {
        padding-left: 1.5rem !important
    }

    .pl-sm-20 {
        padding-left: 2rem !important
    }

    .pr-sm-0 {
        padding-right: 0 !important
    }

    .pr-sm-5 {
        padding-right: .5rem !important
    }

    .pr-sm-10 {
        padding-right: 1rem !important
    }

    .pr-sm-15 {
        padding-right: 1.5rem !important
    }

    .pr-sm-20 {
        padding-right: 2rem !important
    }

    .m-sm-0 {
        margin: 0 !important
    }

    .m-sm-5 {
        margin: .5rem !important
    }

    .m-sm-10 {
        margin: 1rem !important
    }

    .m-sm-15 {
        margin: 1.5rem !important
    }

    .m-sm-20 {
        margin: 2rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mx-sm-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-sm-5 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-sm-10 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-sm-15 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-sm-20 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-sm-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-sm-5 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-sm-10 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-sm-15 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-sm-20 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mt-sm-5 {
        margin-top: .5rem !important
    }

    .mt-sm-10 {
        margin-top: 1rem !important
    }

    .mt-sm-15 {
        margin-top: 1.5rem !important
    }

    .mt-sm-20 {
        margin-top: 2rem !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .mb-sm-5 {
        margin-bottom: .5rem !important
    }

    .mb-sm-10 {
        margin-bottom: 1rem !important
    }

    .mb-sm-15 {
        margin-bottom: 1.5rem !important
    }

    .mb-sm-20 {
        margin-bottom: 2rem !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-0 {
        margin-left: 0 !important
    }

    .ml-sm-5 {
        margin-left: .5rem !important
    }

    .ml-sm-10 {
        margin-left: 1rem !important
    }

    .ml-sm-15 {
        margin-left: 1.5rem !important
    }

    .ml-sm-20 {
        margin-left: 2rem !important
    }

    .ml-sm-auto {
        margin-left: auto !important
    }

    .mr-sm-0 {
        margin-right: 0 !important
    }

    .mr-sm-5 {
        margin-right: .5rem !important
    }

    .mr-sm-10 {
        margin-right: 1rem !important
    }

    .mr-sm-15 {
        margin-right: 1.5rem !important
    }

    .mr-sm-20 {
        margin-right: 2rem !important
    }

    .mr-sm-auto {
        margin-right: auto !important
    }
}

@media (min-width:769px) {
    .p-md-0 {
        padding: 0 !important
    }

    .p-md-5 {
        padding: .5rem !important
    }

    .p-md-10 {
        padding: 1rem !important
    }

    .p-md-15 {
        padding: 1.5rem !important
    }

    .p-md-20 {
        padding: 2rem !important
    }

    .px-md-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-md-5 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-md-10 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-md-15 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-md-20 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-md-5 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-md-10 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-md-15 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-md-20 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pt-md-5 {
        padding-top: .5rem !important
    }

    .pt-md-10 {
        padding-top: 1rem !important
    }

    .pt-md-15 {
        padding-top: 1.5rem !important
    }

    .pt-md-20 {
        padding-top: 2rem !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pb-md-5 {
        padding-bottom: .5rem !important
    }

    .pb-md-10 {
        padding-bottom: 1rem !important
    }

    .pb-md-15 {
        padding-bottom: 1.5rem !important
    }

    .pb-md-20 {
        padding-bottom: 2rem !important
    }

    .pl-md-0 {
        padding-left: 0 !important
    }

    .pl-md-5 {
        padding-left: .5rem !important
    }

    .pl-md-10 {
        padding-left: 1rem !important
    }

    .pl-md-15 {
        padding-left: 1.5rem !important
    }

    .pl-md-20 {
        padding-left: 2rem !important
    }

    .pr-md-0 {
        padding-right: 0 !important
    }

    .pr-md-5 {
        padding-right: .5rem !important
    }

    .pr-md-10 {
        padding-right: 1rem !important
    }

    .pr-md-15 {
        padding-right: 1.5rem !important
    }

    .pr-md-20 {
        padding-right: 2rem !important
    }

    .m-md-0 {
        margin: 0 !important
    }

    .m-md-5 {
        margin: .5rem !important
    }

    .m-md-10 {
        margin: 1rem !important
    }

    .m-md-15 {
        margin: 1.5rem !important
    }

    .m-md-20 {
        margin: 2rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mx-md-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-md-5 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-md-10 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-md-15 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-md-20 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-md-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-md-5 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-md-10 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-md-15 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-md-20 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mt-md-5 {
        margin-top: .5rem !important
    }

    .mt-md-10 {
        margin-top: 1rem !important
    }

    .mt-md-15 {
        margin-top: 1.5rem !important
    }

    .mt-md-20 {
        margin-top: 2rem !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .mb-md-5 {
        margin-bottom: .5rem !important
    }

    .mb-md-10 {
        margin-bottom: 1rem !important
    }

    .mb-md-15 {
        margin-bottom: 1.5rem !important
    }

    .mb-md-20 {
        margin-bottom: 2rem !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-0 {
        margin-left: 0 !important
    }

    .ml-md-5 {
        margin-left: .5rem !important
    }

    .ml-md-10 {
        margin-left: 1rem !important
    }

    .ml-md-15 {
        margin-left: 1.5rem !important
    }

    .ml-md-20 {
        margin-left: 2rem !important
    }

    .ml-md-auto {
        margin-left: auto !important
    }

    .mr-md-0 {
        margin-right: 0 !important
    }

    .mr-md-5 {
        margin-right: .5rem !important
    }

    .mr-md-10 {
        margin-right: 1rem !important
    }

    .mr-md-15 {
        margin-right: 1.5rem !important
    }

    .mr-md-20 {
        margin-right: 2rem !important
    }

    .mr-md-auto {
        margin-right: auto !important
    }
}

@media (min-width:993px) {
    .p-lg-0 {
        padding: 0 !important
    }

    .p-lg-5 {
        padding: .5rem !important
    }

    .p-lg-10 {
        padding: 1rem !important
    }

    .p-lg-15 {
        padding: 1.5rem !important
    }

    .p-lg-20 {
        padding: 2rem !important
    }

    .px-lg-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-lg-5 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-lg-10 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-lg-15 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-lg-20 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-lg-5 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-lg-10 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-lg-15 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-lg-20 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pt-lg-5 {
        padding-top: .5rem !important
    }

    .pt-lg-10 {
        padding-top: 1rem !important
    }

    .pt-lg-15 {
        padding-top: 1.5rem !important
    }

    .pt-lg-20 {
        padding-top: 2rem !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pb-lg-5 {
        padding-bottom: .5rem !important
    }

    .pb-lg-10 {
        padding-bottom: 1rem !important
    }

    .pb-lg-15 {
        padding-bottom: 1.5rem !important
    }

    .pb-lg-20 {
        padding-bottom: 2rem !important
    }

    .pl-lg-0 {
        padding-left: 0 !important
    }

    .pl-lg-5 {
        padding-left: .5rem !important
    }

    .pl-lg-10 {
        padding-left: 1rem !important
    }

    .pl-lg-15 {
        padding-left: 1.5rem !important
    }

    .pl-lg-20 {
        padding-left: 2rem !important
    }

    .pr-lg-0 {
        padding-right: 0 !important
    }

    .pr-lg-5 {
        padding-right: .5rem !important
    }

    .pr-lg-10 {
        padding-right: 1rem !important
    }

    .pr-lg-15 {
        padding-right: 1.5rem !important
    }

    .pr-lg-20 {
        padding-right: 2rem !important
    }

    .m-lg-0 {
        margin: 0 !important
    }

    .m-lg-5 {
        margin: .5rem !important
    }

    .m-lg-10 {
        margin: 1rem !important
    }

    .m-lg-15 {
        margin: 1.5rem !important
    }

    .m-lg-20 {
        margin: 2rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mx-lg-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-lg-5 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-lg-10 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-lg-15 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-lg-20 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-lg-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-lg-5 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-lg-10 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-lg-15 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-lg-20 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mt-lg-5 {
        margin-top: .5rem !important
    }

    .mt-lg-10 {
        margin-top: 1rem !important
    }

    .mt-lg-15 {
        margin-top: 1.5rem !important
    }

    .mt-lg-20 {
        margin-top: 2rem !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mb-lg-5 {
        margin-bottom: .5rem !important
    }

    .mb-lg-10 {
        margin-bottom: 1rem !important
    }

    .mb-lg-15 {
        margin-bottom: 1.5rem !important
    }

    .mb-lg-20 {
        margin-bottom: 2rem !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-0 {
        margin-left: 0 !important
    }

    .ml-lg-5 {
        margin-left: .5rem !important
    }

    .ml-lg-10 {
        margin-left: 1rem !important
    }

    .ml-lg-15 {
        margin-left: 1.5rem !important
    }

    .ml-lg-20 {
        margin-left: 2rem !important
    }

    .ml-lg-auto {
        margin-left: auto !important
    }

    .mr-lg-0 {
        margin-right: 0 !important
    }

    .mr-lg-5 {
        margin-right: .5rem !important
    }

    .mr-lg-10 {
        margin-right: 1rem !important
    }

    .mr-lg-15 {
        margin-right: 1.5rem !important
    }

    .mr-lg-20 {
        margin-right: 2rem !important
    }

    .mr-lg-auto {
        margin-right: auto !important
    }
}

@media (min-width:1201px) {
    .p-xl-0 {
        padding: 0 !important
    }

    .p-xl-5 {
        padding: .5rem !important
    }

    .p-xl-10 {
        padding: 1rem !important
    }

    .p-xl-15 {
        padding: 1.5rem !important
    }

    .p-xl-20 {
        padding: 2rem !important
    }

    .px-xl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-xl-5 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-xl-10 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-xl-15 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-xl-20 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xl-5 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xl-10 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xl-15 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xl-20 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pt-xl-5 {
        padding-top: .5rem !important
    }

    .pt-xl-10 {
        padding-top: 1rem !important
    }

    .pt-xl-15 {
        padding-top: 1.5rem !important
    }

    .pt-xl-20 {
        padding-top: 2rem !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pb-xl-5 {
        padding-bottom: .5rem !important
    }

    .pb-xl-10 {
        padding-bottom: 1rem !important
    }

    .pb-xl-15 {
        padding-bottom: 1.5rem !important
    }

    .pb-xl-20 {
        padding-bottom: 2rem !important
    }

    .pl-xl-0 {
        padding-left: 0 !important
    }

    .pl-xl-5 {
        padding-left: .5rem !important
    }

    .pl-xl-10 {
        padding-left: 1rem !important
    }

    .pl-xl-15 {
        padding-left: 1.5rem !important
    }

    .pl-xl-20 {
        padding-left: 2rem !important
    }

    .pr-xl-0 {
        padding-right: 0 !important
    }

    .pr-xl-5 {
        padding-right: .5rem !important
    }

    .pr-xl-10 {
        padding-right: 1rem !important
    }

    .pr-xl-15 {
        padding-right: 1.5rem !important
    }

    .pr-xl-20 {
        padding-right: 2rem !important
    }

    .m-xl-0 {
        margin: 0 !important
    }

    .m-xl-5 {
        margin: .5rem !important
    }

    .m-xl-10 {
        margin: 1rem !important
    }

    .m-xl-15 {
        margin: 1.5rem !important
    }

    .m-xl-20 {
        margin: 2rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mx-xl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-xl-5 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-xl-10 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-xl-15 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-xl-20 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-xl-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xl-5 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xl-10 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xl-15 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xl-20 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mt-xl-5 {
        margin-top: .5rem !important
    }

    .mt-xl-10 {
        margin-top: 1rem !important
    }

    .mt-xl-15 {
        margin-top: 1.5rem !important
    }

    .mt-xl-20 {
        margin-top: 2rem !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .mb-xl-5 {
        margin-bottom: .5rem !important
    }

    .mb-xl-10 {
        margin-bottom: 1rem !important
    }

    .mb-xl-15 {
        margin-bottom: 1.5rem !important
    }

    .mb-xl-20 {
        margin-bottom: 2rem !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-0 {
        margin-left: 0 !important
    }

    .ml-xl-5 {
        margin-left: .5rem !important
    }

    .ml-xl-10 {
        margin-left: 1rem !important
    }

    .ml-xl-15 {
        margin-left: 1.5rem !important
    }

    .ml-xl-20 {
        margin-left: 2rem !important
    }

    .ml-xl-auto {
        margin-left: auto !important
    }

    .mr-xl-0 {
        margin-right: 0 !important
    }

    .mr-xl-5 {
        margin-right: .5rem !important
    }

    .mr-xl-10 {
        margin-right: 1rem !important
    }

    .mr-xl-15 {
        margin-right: 1.5rem !important
    }

    .mr-xl-20 {
        margin-right: 2rem !important
    }

    .mr-xl-auto {
        margin-right: auto !important
    }
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important
}

.d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
}

@media (min-width:577px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-sm-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:769px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-md-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:993px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-lg-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:1201px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-xl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (max-width:576px) {
    .d-xs-only-none {
        display: none !important
    }
}

@media (min-width:577px) and (max-width:768px) {
    .d-sm-only-none {
        display: none !important
    }
}

@media (min-width:769px) and (max-width:992px) {
    .d-md-only-none {
        display: none !important
    }
}

@media (min-width:993px) and (max-width:1200px) {
    .d-lg-only-none {
        display: none !important
    }
}

@media (min-width:1201px) {
    .d-xl-only-none {
        display: none !important
    }
}

.hidden-xs-and-up {
    display: none !important
}

@media (max-width:576px) {
    .hidden-xs-and-down {
        display: none !important
    }
}

@media (min-width:577px) {
    .hidden-sm-and-up {
        display: none !important
    }
}

@media (max-width:768px) {
    .hidden-sm-and-down {
        display: none !important
    }
}

@media (min-width:769px) {
    .hidden-md-and-up {
        display: none !important
    }
}

@media (max-width:992px) {
    .hidden-md-and-down {
        display: none !important
    }
}

@media (min-width:993px) {
    .hidden-lg-and-up {
        display: none !important
    }
}

@media (max-width:1200px) {
    .hidden-lg-and-down {
        display: none !important
    }
}

@media (min-width:1201px) {
    .hidden-xl-and-up {
        display: none !important
    }
}

.hidden-xl-and-down {
    display: none !important
}

body:not(.dark-mode) .hidden-lm {
    visibility: hidden !important;
    display: none !important
}

.dark-mode .hidden-dm {
    visibility: hidden !important;
    display: none !important
}

.w-50 {
    width: 5rem !important
}

.w-100 {
    width: 10rem !important
}

.w-150 {
    width: 15rem !important
}

.w-200 {
    width: 20rem !important
}

.w-250 {
    width: 25rem !important
}

.w-300 {
    width: 30rem !important
}

.w-350 {
    width: 35rem !important
}

.w-400 {
    width: 40rem !important
}

.w-450 {
    width: 45rem !important
}

.w-500 {
    width: 50rem !important
}

.w-550 {
    width: 55rem !important
}

.w-600 {
    width: 60rem !important
}

.w-auto {
    width: auto !important
}

.w-quarter {
    width: 25% !important
}

.w-half {
    width: 50% !important
}

.w-three-quarter {
    width: 75% !important
}

.w-full {
    width: 100% !important
}

.mw-full {
    max-width: 100% !important
}

@media (min-width:577px) {
    .w-sm-50 {
        width: 5rem !important
    }

    .w-sm-100 {
        width: 10rem !important
    }

    .w-sm-150 {
        width: 15rem !important
    }

    .w-sm-200 {
        width: 20rem !important
    }

    .w-sm-250 {
        width: 25rem !important
    }

    .w-sm-300 {
        width: 30rem !important
    }

    .w-sm-350 {
        width: 35rem !important
    }

    .w-sm-400 {
        width: 40rem !important
    }

    .w-sm-450 {
        width: 45rem !important
    }

    .w-sm-500 {
        width: 50rem !important
    }

    .w-sm-550 {
        width: 55rem !important
    }

    .w-sm-600 {
        width: 60rem !important
    }

    .w-sm-auto {
        width: auto !important
    }

    .w-sm-quarter {
        width: 25% !important
    }

    .w-sm-half {
        width: 50% !important
    }

    .w-sm-three-quarter {
        width: 75% !important
    }

    .w-sm-full {
        width: 100% !important
    }

    .mw-sm-full {
        max-width: 100% !important
    }
}

@media (min-width:769px) {
    .w-md-50 {
        width: 5rem !important
    }

    .w-md-100 {
        width: 10rem !important
    }

    .w-md-150 {
        width: 15rem !important
    }

    .w-md-200 {
        width: 20rem !important
    }

    .w-md-250 {
        width: 25rem !important
    }

    .w-md-300 {
        width: 30rem !important
    }

    .w-md-350 {
        width: 35rem !important
    }

    .w-md-400 {
        width: 40rem !important
    }

    .w-md-450 {
        width: 45rem !important
    }

    .w-md-500 {
        width: 50rem !important
    }

    .w-md-550 {
        width: 55rem !important
    }

    .w-md-600 {
        width: 60rem !important
    }

    .w-md-auto {
        width: auto !important
    }

    .w-md-quarter {
        width: 25% !important
    }

    .w-md-half {
        width: 50% !important
    }

    .w-md-three-quarter {
        width: 75% !important
    }

    .w-md-full {
        width: 100% !important
    }

    .mw-md-full {
        max-width: 100% !important
    }
}

@media (min-width:993px) {
    .w-lg-50 {
        width: 5rem !important
    }

    .w-lg-100 {
        width: 10rem !important
    }

    .w-lg-150 {
        width: 15rem !important
    }

    .w-lg-200 {
        width: 20rem !important
    }

    .w-lg-250 {
        width: 25rem !important
    }

    .w-lg-300 {
        width: 30rem !important
    }

    .w-lg-350 {
        width: 35rem !important
    }

    .w-lg-400 {
        width: 40rem !important
    }

    .w-lg-450 {
        width: 45rem !important
    }

    .w-lg-500 {
        width: 50rem !important
    }

    .w-lg-550 {
        width: 55rem !important
    }

    .w-lg-600 {
        width: 60rem !important
    }

    .w-lg-auto {
        width: auto !important
    }

    .w-lg-quarter {
        width: 25% !important
    }

    .w-lg-half {
        width: 50% !important
    }

    .w-lg-three-quarter {
        width: 75% !important
    }

    .w-lg-full {
        width: 100% !important
    }

    .mw-lg-full {
        max-width: 100% !important
    }
}

@media (min-width:1201px) {
    .w-xl-50 {
        width: 5rem !important
    }

    .w-xl-100 {
        width: 10rem !important
    }

    .w-xl-150 {
        width: 15rem !important
    }

    .w-xl-200 {
        width: 20rem !important
    }

    .w-xl-250 {
        width: 25rem !important
    }

    .w-xl-300 {
        width: 30rem !important
    }

    .w-xl-350 {
        width: 35rem !important
    }

    .w-xl-400 {
        width: 40rem !important
    }

    .w-xl-450 {
        width: 45rem !important
    }

    .w-xl-500 {
        width: 50rem !important
    }

    .w-xl-550 {
        width: 55rem !important
    }

    .w-xl-600 {
        width: 60rem !important
    }

    .w-xl-auto {
        width: auto !important
    }

    .w-xl-quarter {
        width: 25% !important
    }

    .w-xl-half {
        width: 50% !important
    }

    .w-xl-three-quarter {
        width: 75% !important
    }

    .w-xl-full {
        width: 100% !important
    }

    .mw-xl-full {
        max-width: 100% !important
    }
}

.h-50 {
    height: 5rem !important
}

.h-100 {
    height: 10rem !important
}

.h-150 {
    height: 15rem !important
}

.h-200 {
    height: 20rem !important
}

.h-250 {
    height: 25rem !important
}

.h-300 {
    height: 30rem !important
}

.h-350 {
    height: 35rem !important
}

.h-400 {
    height: 40rem !important
}

.h-450 {
    height: 45rem !important
}

.h-500 {
    height: 50rem !important
}

.h-550 {
    height: 55rem !important
}

.h-600 {
    height: 60rem !important
}

.h-auto {
    height: auto !important
}

.h-quarter {
    height: 25% !important
}

.h-half {
    height: 50% !important
}

.h-three-quarter {
    height: 75% !important
}

.h-full {
    height: 100% !important
}

.mh-full {
    max-height: 100% !important
}

@media (min-width:577px) {
    .h-sm-50 {
        height: 5rem !important
    }

    .h-sm-100 {
        height: 10rem !important
    }

    .h-sm-150 {
        height: 15rem !important
    }

    .h-sm-200 {
        height: 20rem !important
    }

    .h-sm-250 {
        height: 25rem !important
    }

    .h-sm-300 {
        height: 30rem !important
    }

    .h-sm-350 {
        height: 35rem !important
    }

    .h-sm-400 {
        height: 40rem !important
    }

    .h-sm-450 {
        height: 45rem !important
    }

    .h-sm-500 {
        height: 50rem !important
    }

    .h-sm-550 {
        height: 55rem !important
    }

    .h-sm-600 {
        height: 60rem !important
    }

    .h-sm-auto {
        height: auto !important
    }

    .h-sm-quarter {
        height: 25% !important
    }

    .h-sm-half {
        height: 50% !important
    }

    .h-sm-three-quarter {
        height: 75% !important
    }

    .h-sm-full {
        height: 100% !important
    }

    .mh-sm-full {
        max-height: 100% !important
    }
}

@media (min-width:769px) {
    .h-md-50 {
        height: 5rem !important
    }

    .h-md-100 {
        height: 10rem !important
    }

    .h-md-150 {
        height: 15rem !important
    }

    .h-md-200 {
        height: 20rem !important
    }

    .h-md-250 {
        height: 25rem !important
    }

    .h-md-300 {
        height: 30rem !important
    }

    .h-md-350 {
        height: 35rem !important
    }

    .h-md-400 {
        height: 40rem !important
    }

    .h-md-450 {
        height: 45rem !important
    }

    .h-md-500 {
        height: 50rem !important
    }

    .h-md-550 {
        height: 55rem !important
    }

    .h-md-600 {
        height: 60rem !important
    }

    .h-md-auto {
        height: auto !important
    }

    .h-md-quarter {
        height: 25% !important
    }

    .h-md-half {
        height: 50% !important
    }

    .h-md-three-quarter {
        height: 75% !important
    }

    .h-md-full {
        height: 100% !important
    }

    .mh-md-full {
        max-height: 100% !important
    }
}

@media (min-width:993px) {
    .h-lg-50 {
        height: 5rem !important
    }

    .h-lg-100 {
        height: 10rem !important
    }

    .h-lg-150 {
        height: 15rem !important
    }

    .h-lg-200 {
        height: 20rem !important
    }

    .h-lg-250 {
        height: 25rem !important
    }

    .h-lg-300 {
        height: 30rem !important
    }

    .h-lg-350 {
        height: 35rem !important
    }

    .h-lg-400 {
        height: 40rem !important
    }

    .h-lg-450 {
        height: 45rem !important
    }

    .h-lg-500 {
        height: 50rem !important
    }

    .h-lg-550 {
        height: 55rem !important
    }

    .h-lg-600 {
        height: 60rem !important
    }

    .h-lg-auto {
        height: auto !important
    }

    .h-lg-quarter {
        height: 25% !important
    }

    .h-lg-half {
        height: 50% !important
    }

    .h-lg-three-quarter {
        height: 75% !important
    }

    .h-lg-full {
        height: 100% !important
    }

    .mh-lg-full {
        max-height: 100% !important
    }
}

@media (min-width:1201px) {
    .h-xl-50 {
        height: 5rem !important
    }

    .h-xl-100 {
        height: 10rem !important
    }

    .h-xl-150 {
        height: 15rem !important
    }

    .h-xl-200 {
        height: 20rem !important
    }

    .h-xl-250 {
        height: 25rem !important
    }

    .h-xl-300 {
        height: 30rem !important
    }

    .h-xl-350 {
        height: 35rem !important
    }

    .h-xl-400 {
        height: 40rem !important
    }

    .h-xl-450 {
        height: 45rem !important
    }

    .h-xl-500 {
        height: 50rem !important
    }

    .h-xl-550 {
        height: 55rem !important
    }

    .h-xl-600 {
        height: 60rem !important
    }

    .h-xl-auto {
        height: auto !important
    }

    .h-xl-quarter {
        height: 25% !important
    }

    .h-xl-half {
        height: 50% !important
    }

    .h-xl-three-quarter {
        height: 75% !important
    }

    .h-xl-full {
        height: 100% !important
    }

    .mh-xl-full {
        max-height: 100% !important
    }
}

.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
}

.flex-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
}

.flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
}

.flex-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
}

.flex-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
}

.flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
}

.justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
}

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
}

.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
}

.align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important
}

.align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
}

.align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
}

@media (min-width:577px) {
    .flex-sm-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-sm-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-sm-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-sm-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-sm-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-sm-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-sm-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-sm-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-sm-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-sm-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:769px) {
    .flex-md-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-md-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-md-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-md-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-md-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-md-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-md-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-md-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-md-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-md-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-md-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:993px) {
    .flex-lg-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-lg-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-lg-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-lg-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-lg-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-lg-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-lg-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-lg-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-lg-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-lg-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:1201px) {
    .flex-xl-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xl-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-xl-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-xl-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-xl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-xl-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-xl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-xl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-xl-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-xl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

.flex-reset {
    -ms-flex: 0 1 auto !important;
    flex: 0 1 auto !important
}

@media (min-width:577px) {
    .flex-sm-reset {
        -ms-flex: 0 1 auto !important;
        flex: 0 1 auto !important
    }
}

@media (min-width:769px) {
    .flex-md-reset {
        -ms-flex: 0 1 auto !important;
        flex: 0 1 auto !important
    }
}

@media (min-width:993px) {
    .flex-lg-reset {
        -ms-flex: 0 1 auto !important;
        flex: 0 1 auto !important
    }
}

@media (min-width:1201px) {
    .flex-xl-reset {
        -ms-flex: 0 1 auto !important;
        flex: 0 1 auto !important
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.border {
    border: 1px solid rgba(0, 0, 0, .2) !important
}

.border-top {
    border-top: 1px solid rgba(0, 0, 0, .2) !important
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, .2) !important
}

.border-left {
    border-left: 1px solid rgba(0, 0, 0, .2) !important
}

.border-right {
    border-right: 1px solid rgba(0, 0, 0, .2) !important
}

.dark-mode .border,
.dark-mode .border-bottom,
.dark-mode .border-left,
.dark-mode .border-right,
.dark-mode .border-top {
    border-color: rgba(255, 255, 255, .2) !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: none !important
}

.border-bottom-0 {
    border-bottom: none !important
}

.border-left-0 {
    border-left: none !important
}

.border-right-0 {
    border-right: none !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded {
    border-radius: .4rem !important
}

.rounded-top {
    border-top-left-radius: .4rem !important;
    border-top-right-radius: .4rem !important
}

.rounded-bottom,
.rounded-left {
    border-bottom-left-radius: .4rem !important
}

.rounded-bottom {
    border-bottom-right-radius: .4rem !important
}

.rounded-left {
    border-top-left-radius: .4rem !important
}

.rounded-right {
    border-top-right-radius: .4rem !important;
    border-bottom-right-radius: .4rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.border-transparent,
.dark-mode .border-transparent {
    border: 1px solid transparent !important
}

.border-top-transparent,
.dark-mode .border-top-transparent {
    border-top: 1px solid transparent !important
}

.border-bottom-transparent,
.dark-mode .border-bottom-transparent {
    border-bottom: 1px solid transparent !important
}

.border-left-transparent,
.dark-mode .border-left-transparent {
    border-left: 1px solid transparent !important
}

.border-right-transparent,
.dark-mode .border-right-transparent {
    border-right: 1px solid transparent !important
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media (min-width:577px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media (min-width:769px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media (min-width:993px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

@media (min-width:1201px) {
    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important
}

.text-justify {
    text-align: justify !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media (min-width:577px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:769px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:993px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width:1201px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-italic {
    font-style: italic !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-break {
    word-wrap: break-word !important
}

.text-reset {
    color: inherit !important
}

.text-extra-letter-spacing,
.text-extra-letter-spacing * {
    letter-spacing: .3px
}

.font-size-12 {
    font-size: 1.2rem !important
}

.font-size-14 {
    font-size: 1.4rem !important
}

.font-size-16 {
    font-size: 1.6rem !important
}

.font-size-18 {
    font-size: 1.8rem !important
}

.font-size-20 {
    font-size: 2rem !important
}

.font-size-22 {
    font-size: 2.2rem !important
}

.font-size-24 {
    font-size: 2.4rem !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-lighter {
    font-weight: lighter !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-medium {
    font-weight: 500 !important
}

.font-weight-semi-bold {
    font-weight: 600 !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-weight-bolder {
    font-weight: bolder !important
}

.text-muted {
    color: rgba(0, 0, 0, .6) !important
}

.dark-mode .text-muted {
    color: rgba(255, 255, 255, .6) !important
}

.text-primary {
    color: #1890ff !important
}

a.text-primary:hover {
    color: #45a3fb !important
}

a.text-primary:focus {
    color: #45a3fb !important
}

.text-success {
    color: #0be881 !important
}

a.text-success:hover {
    color: #81eea8 !important
}

a.text-success:focus {
    color: #81eea8 !important
}

.text-secondary {
    color: #ffc312 !important
}

a.text-secondary:hover {
    color: #fbd35b !important
}

a.text-secondary:focus {
    color: #fbd35b !important
}

.text-danger {
    color: #ff4d4f !important
}

a.text-danger:hover {
    color: #ed586c !important
}

a.text-danger:focus {
    color: #ed586c !important
}

.text-white {
    color: #fff !important
}

.text-light {
    color: rgba(255, 255, 255, .8) !important
}

a.text-light:hover {
    color: rgba(255, 255, 255, .65) !important
}

a.text-light:focus {
    color: rgba(255, 255, 255, .65) !important
}

.text-dark {
    color: rgba(0, 0, 0, .85) !important
}

a.text-dark:hover {
    color: rgba(0, 0, 0, .7) !important
}

a.text-dark:focus {
    color: rgba(0, 0, 0, .7) !important
}

.dark-mode .text-danger,
.dark-mode .text-dark,
.dark-mode .text-primary,
.dark-mode .text-secondary,
.dark-mode .text-success {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.bg-white {
    background-color: #fff !important
}

.bg-light {
    background-color: #ecf0f1 !important
}

.bg-transparent {
    background-color: transparent !important
}

.bg-primary {
    background-color: #1890ff !important
}

.bg-success {
    background-color: #0be881 !important
}

.bg-secondary {
    background-color: #ffc312 !important
}

.bg-danger {
    background-color: #ff4d4f !important
}

.bg-dark-light,
.dark-mode .bg-dark-light-dm {
    background-color: #25282c !important
}

.bg-dark,
.dark-mode .bg-dark-dm {
    background-color: #191c20 !important
}

.bg-very-dark,
.dark-mode .bg-very-dark-dm {
    background-color: #111417 !important
}

.dark-mode .bg-danger,
.dark-mode .bg-light,
.dark-mode .bg-primary,
.dark-mode .bg-secondary,
.dark-mode .bg-success,
.dark-mode .bg-white {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-x-hidden {
    overflow-x: hidden !important
}

.overflow-y-hidden {
    overflow-y: hidden !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-x-auto {
    overflow-x: auto !important
}

.overflow-y-auto {
    overflow-y: auto !important
}

.overflow-scroll {
    overflow: scroll !important
}

.overflow-x-scroll {
    overflow-x: scroll !important
}

.overflow-y-scroll {
    overflow-y: scroll !important
}

@media (min-width:577px) {
    .overflow-sm-hidden {
        overflow: hidden !important
    }

    .overflow-x-sm-hidden {
        overflow-x: hidden !important
    }

    .overflow-y-sm-hidden {
        overflow-y: hidden !important
    }

    .overflow-sm-auto {
        overflow: auto !important
    }

    .overflow-x-sm-auto {
        overflow-x: auto !important
    }

    .overflow-y-sm-auto {
        overflow-y: auto !important
    }

    .overflow-sm-scroll {
        overflow: scroll !important
    }

    .overflow-x-sm-scroll {
        overflow-x: scroll !important
    }

    .overflow-y-sm-scroll {
        overflow-y: scroll !important
    }
}

@media (min-width:769px) {
    .overflow-md-hidden {
        overflow: hidden !important
    }

    .overflow-x-md-hidden {
        overflow-x: hidden !important
    }

    .overflow-y-md-hidden {
        overflow-y: hidden !important
    }

    .overflow-md-auto {
        overflow: auto !important
    }

    .overflow-x-md-auto {
        overflow-x: auto !important
    }

    .overflow-y-md-auto {
        overflow-y: auto !important
    }

    .overflow-md-scroll {
        overflow: scroll !important
    }

    .overflow-x-md-scroll {
        overflow-x: scroll !important
    }

    .overflow-y-md-scroll {
        overflow-y: scroll !important
    }
}

@media (min-width:993px) {
    .overflow-lg-hidden {
        overflow: hidden !important
    }

    .overflow-x-lg-hidden {
        overflow-x: hidden !important
    }

    .overflow-y-lg-hidden {
        overflow-y: hidden !important
    }

    .overflow-lg-auto {
        overflow: auto !important
    }

    .overflow-x-lg-auto {
        overflow-x: auto !important
    }

    .overflow-y-lg-auto {
        overflow-y: auto !important
    }

    .overflow-lg-scroll {
        overflow: scroll !important
    }

    .overflow-x-lg-scroll {
        overflow-x: scroll !important
    }

    .overflow-y-lg-scroll {
        overflow-y: scroll !important
    }
}

@media (min-width:1201px) {
    .overflow-xl-hidden {
        overflow: hidden !important
    }

    .overflow-x-xl-hidden {
        overflow-x: hidden !important
    }

    .overflow-y-xl-hidden {
        overflow-y: hidden !important
    }

    .overflow-xl-auto {
        overflow: auto !important
    }

    .overflow-x-xl-auto {
        overflow-x: auto !important
    }

    .overflow-y-xl-auto {
        overflow-y: auto !important
    }

    .overflow-xl-scroll {
        overflow: scroll !important
    }

    .overflow-x-xl-scroll {
        overflow-x: scroll !important
    }

    .overflow-y-xl-scroll {
        overflow-y: scroll !important
    }
}